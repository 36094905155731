<template>
  <v-card class="mt-3 mb-3" width="100%">
    <v-card-title>
      Alternativni primaoci
    </v-card-title>
    <v-card-subtitle>
      <v-switch
        label="Veze gde je originalni primalac"
        v-model="asOriginalReceiver"
        @click="onSwitch"
      >
      </v-switch>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="customersAltReceiversList"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
    >
      <template v-slot:[`item.altCustomerOnly`]="{ item }">
        <v-icon>{{ item.altCustomerOnly === true ? "mdi-check" : "mdi-close" }}</v-icon>
      </template>

      <template v-slot:[`item.documentTypeId`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ getDocumentType(item.documentTypeId).letterIcon }}</v-icon>
          </template>
          <span>{{getDocumentType(item.documentTypeId).name}}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="onDeleteAltReceiverLink(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmDelete = false">
            Odustani
          </v-btn>
          <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
            Obriši
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import * as customersAPI from '../api/customers.js';
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: "CustomersAltReceiversList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      asOriginalReceiver: true,
      dialogConfirmDelete: false,
      customersAltReceiversList: [],

      selectedLinkIdToDelete: null,
      deleting: false,

      headers: [
          { text: 'ID', value: 'id', width: "12%", sortable: false },
          { text: 'Originalni primalac', value: 'originalReceiver.name', sortable: false },
          { text: 'Alternativni primalac', value: 'alternativeReceiver.name', sortable: false },
          { text: 'Slanje samo alternativnom', value: 'altCustomerOnly', width: "15%", sortable: false },
          { text: 'Tip dokumenta', value: 'documentTypeId', width: "15%", sortable: false },
          { text: '', value: 'actions', width: "10%", sortable: false }
      ],
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false
    }
  },
  watch: {
    options: {
      handler () {
        this.getAltReceiversFromAPI();
      },
      deep: true,
    }
  },
  mounted () {
    this.getAltReceiversFromAPI()
  },
  methods: {
    getAltReceiversFromAPI() {
      this.loading = true;

      let pageNum = this.options.page;
      let pageSize = this.options.itemsPerPage;
      customersAPI.getAlternativeReceiversOfCustomer(this.customerId, this.asOriginalReceiver, pageNum, pageSize, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.customersAltReceiversList = responseData
        })
        .catch(() => {
          this.customersAltReceiversList = []
          this.loading = false
        })
    },

    onSwitch() {
      this.getAltReceiversFromAPI();
    },

    getDocumentType(id) {
      return DocumentTypes.getById(id);
    },

    onDeleteAltReceiverLink(link) {
      this.selectedLinkIdToDelete = link.id;
      this.dialogConfirmDelete = true;
    },

    onConfirmDelete() {
      if (this.selectedLinkIdToDelete === null) {
        this.dialogConfirmDelete = false;
        return;
      }

      this.deleting = true;

      customersAPI.deleteAltReceiverLink(this.selectedLinkIdToDelete, this.$store.getters.token)
        .then(() => {
          return null
        })
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogEditApiOptions = false;

          this.getAltReceiversFromAPI();
        })
        .catch(() => {
          this.deleting = false;
        });
    },
  }
}
</script>
