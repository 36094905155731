export default class DocumentTypes {
  static getDocumentTypes() {
    return [
      new DocumentType(1, "Faktura", "mdi-alpha-f-box"),
      new DocumentType(2, "Narudžbina", "mdi-alpha-n-box"),
      new DocumentType(3, "Odgovor", "mdi-alpha-o-box"),
      new DocumentType(4, "Uslovi", "mdi-alpha-u-box"),
      new DocumentType(5, "Faktura PDF", "mdi-pdf-box"),
      new DocumentType(6, "Knjiška odobrenja", "mdi-alpha-k-box"),
      new DocumentType(7, "Izvod otvorenih stavki", "mdi-alpha-i-box"),
      new DocumentType(8, "Otpremnica", "mdi-alpha-t-box"),
      new DocumentType(9, "Povratnica", "mdi-alpha-p-box"),
      new DocumentType(10, "Lager", "mdi-alpha-l-box"),
    ]
  }

  static getById(id) {
    return this.getDocumentTypes().filter(dt => dt.id == id)[0];
  }

  static getDocumentTypesForSelectElement() {
    return this.getDocumentTypes().map(x => ({
        value: x.id,
        text: x.name
    }));
  }
}

class DocumentType {
  constructor(id, name, icon) {
    this.id = id;
    this.name = name;
    this.letterIcon = icon;
  }
}
