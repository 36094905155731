<template>
  <v-container class="mt-14">
    <v-row aalign="center" justify="center">
      <v-col sm="6">
        <add-api-options
          :copiedApiOptions="apiOptions"
          :propCustomer="customer"
          @companySelected="onCompanySelected"/>
      </v-col>

      <v-col sm="7">
        <api-options-list
          :companyId="selectedCompanyId"
          @apiOptionsCopied="passCopiedApiOptions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCustomers from "../api/customers.js";

import AddApiOptions from "../components/AddApiOptions.vue";
import ApiOptionsList from "../components/ApiOptionsList.vue";

export default {
  name: "AddApiOptionsPage",
  components: {
    AddApiOptions,
    ApiOptionsList
  },
  data() {
    return {
      selectedCompanyId: null,
      apiOptions: null,
      customer: null
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getCustomer(id);
    }
  },
  methods: {
    onCompanySelected(compId) {
      this.selectedCompanyId = compId;
    },

    passCopiedApiOptions(apiOptions) {
      this.apiOptions = apiOptions;
    },

    getCustomer(id) {
      this.loading = true;

      apiCustomers.getCustomer(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customer = responseData;
          this.selectedCompanyId = this.customer.companyId;
          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.customer = null;
          this.loading = false;
        })
    },
  }
}
</script>
