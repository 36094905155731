<template>
  <v-dialog v-model="dialogNewCompanyFileNameFormat" @keydown.esc="onCancel" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" dark v-bind="attrs" v-on="on">
        Poveži šablon sa celom kompanijom
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Povezivanje kompanije i šablona</v-card-title>

      <v-card-text class="mt-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="2" class="mt-3">
                  <find-company-dialog
                    @update="companySelected"
                    :isSmall="true"
                    icon="mdi-magnify"
                    buttonText=""
                    toolbarText="Izaberi kompaniju"
                  />
                </v-col>
                <v-col sm="10">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Kompanija</v-list-item-title>
                        <v-list-item-subtitle>{{this.selectedCompany.companyName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="2" class="mt-3">
                  <find-file-name-format-dialog
                    @chosenFileNameFormat="fileNameFormatSelected"
                    :isSmall="true"
                    icon="mdi-magnify"
                    buttonText=""
                    toolbarText="Izaberi šablon"
                  />
                </v-col>
                <v-col sm="10">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Šablon</v-list-item-title>
                        <v-list-item-subtitle>{{this.selectedFileNameFormat.template}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-btn class="error ma-2 white--text" text @click="onCancel">
          Odustani
          <v-icon right>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          class="primary ma-2 white--text"
          text
          @click="onAddCompanyFileNameFormat"
          :loading="postingToApi"
        >
          Dodaj veze
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>

      <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";

import FindFileNameFormatDialog from "./FindFileNameFormatDialog.vue";
import FindCompanyDialog from "./FindCompanyDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddCompanyFileNameFormat",
  components: {
    Snackbar,
    FindFileNameFormatDialog,
    FindCompanyDialog
  },
  data: function () {
    return {
      dialogNewCompanyFileNameFormat: false,
      postingToApi: false,

      selectedCompany: {
        companyName: null
      },

      selectedFileNameFormat: {
        template: ""
      },

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  methods: {
    onAddCompanyFileNameFormat() {
      this.postingToApi = true;

      let toPost = this.prepareForPost();

      dispatcherOptionsApi
        .addCompanyFileNameFormat(toPost, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodato " + responseData.length + " veza izmedju šablona i kompanije ID: " + this.selectedCompany.id);

          // cekaj 5s pa zatvori modalni prozor
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;

          // cekaj 5s pa zatvori modalni prozor
          setTimeout(function () {
            // zatvaranje modalnog prozora
            this.dialogNewCompany = false;
          }, 5000);
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    companySelected(company) {
      this.selectedCompany = company;
      this.$emit("companySelected", company.id);
    },

    fileNameFormatSelected(fileNameFormat) {
      this.selectedFileNameFormat = fileNameFormat;
      console.log(this.selectedFileNameFormat);
    },

    prepareForPost() {
      let toPost = {
        companyId: this.selectedCompany.id,
        nameFormatId: this.selectedFileNameFormat.id
      }

      return toPost;
    },

    onCancel () {
      this.dialogNewCompanyFileNameFormat = false;
      this.selectedCompany = {
        companyName: null
      };
      this.selectedFileNameFormat = {
        template: ""
      }
    },
  },
};
</script>
