var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialogFindFileNameFormat = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary",attrs:{"small":_vm.isSmall,"fab":_vm.isCircle,"dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.buttonText)+" "),_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1)]}}]),model:{value:(_vm.dialogFindFileNameFormat),callback:function ($$v) {_vm.dialogFindFileNameFormat=$$v},expression:"dialogFindFileNameFormat"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogFindFileNameFormat = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" Šabloni za nazive fajlova ")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfFileNameFormats,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_datetime(item.createdAt)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }