<template>
  <v-card elevation="4">
    <v-card-title>
      Email opcije
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="listOfEmailOptions"
    :server-items-length="options.page * options.itemsPerPage + 1"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    }"
    no-data-text="Nema rezultata"
    loading-text="Učitavanje podataka... Molimo sačekajte"
    class="elevation-1 row-pointer"
    width="100%"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ format_datetime(item.createdAt) }}</span>
      </template>

      <template v-slot:[`item.documentType`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ getDocumentTypeIcon(item) }}</v-icon>
          </template>
          <span>{{getDocumentTypeTooltip(item)}}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="showCopyButton === true" small class="mr-2" @click="onCopy(item)">mdi-content-copy</v-icon>

        <email-options-details-dialog
          v-if="showCopyButton === false"
          :propEmailOptions="item"
        />

        <edit-email-options-dialog
          v-if="showCopyButton === false"
          :propEmailOptions="item"
          @reloadEmailOptions="getEmailOptionsFromAPI()"
        />
      </template>

      <template v-slot:[`item.emailReceivers`]="{ item }">
        <div v-for="(email, index) in item.emailReceivers" :key="index">
          {{email}}
        </div>
      </template>

      <template v-slot:[`item.ccs`]="{ item }">
        <div v-for="(ccAddress, index) in item.ccs" :key="index">
          {{ccAddress}}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js';
import moment from 'moment';
import DocumentTypes from '../common/documentTypes.js';

import EmailOptionsDetailsDialog from '../components/EmailOptionsDetailsDialog.vue';
import EditEmailOptionsDialog from '../components/EditEmailOptionsDialog.vue';

export default {
  name: "EmailOptionsList",
  components: {
    EmailOptionsDetailsDialog,
    EditEmailOptionsDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      listOfEmailOptions: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%", sortable: false },
          { text: 'Isporučno mesto', value: 'customer.name', width: "15%", sortable: false },
          { text: 'Tip dokumenta', value: 'documentType', width: "3%", sortable: false },
          { text: 'Primaoci', value: 'emailReceivers', width: "15%", sortable: false },
          { text: 'CC', value: 'ccs', width: "15%", sortable: false },
          { text: 'Subject', value: 'subject', sortable: false },
          { text: 'Akcije', value: 'actions', width: "8%", sortable: false }
      ]
    }
  },
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null
    },
    customerId: {
      type: Number,
      required: false,
      default: null
    },
    showCopyButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    companyId: {
      handler() {
        this.getEmailOptionsFromAPI();
      }
    },
    options: {
      handler () {
        this.getEmailOptionsFromAPI();
      },
      deep: true,
    },
  },
  mounted () {
    this.getEmailOptionsFromAPI()
  },
  methods: {
    getEmailOptionsFromAPI() {
      this.loading = true;

      this.options.companyId = this.companyId;
      this.options.customerId = this.customerId;
      apiDispatcherOptions.getEmailOptions(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfEmailOptions = responseData;
        })
        .catch(() => {
          this.listOfEmailOptions = [];
          this.loading = false;
        })
    },

    onCopy(emailOptions) {
      this.$emit("emailOptionsCopied", emailOptions);
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },

    getDocumentTypeIcon(emailOptions) {
      return DocumentTypes.getById(emailOptions.documentType).letterIcon;
    },

    getDocumentTypeTooltip(emailOptions) {
      return DocumentTypes.getById(emailOptions.documentType).name;
    }
  }
}
</script>
