var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_vm._v(" Šabloni za nazive fajlova ")]),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfFileNameFormats,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
    'items-per-page-options': [10, 20, 30, 40, 50],
    'items-per-page-text': 'Broj redova po stranici:'
  },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fileNameFormat.createdAt",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_datetime(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [(_vm.showCopyButton === true)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onCopy(item)}}},[_vm._v("mdi-content-copy")]):_vm._e(),(_vm.showCopyButton === false)?_c('edit-file-name-format-dialog',{attrs:{"propFileNameFormat":item},on:{"reloadFileNameFormats":function($event){return _vm.getFileNameFormatsFromAPI()}}}):_vm._e()]}},{key:"item.documentType",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getDocumentTypeIcon(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDocumentTypeTooltip(item)))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }