<template>
  <v-dialog v-model="dialogEditApiOptions" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
    </template>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Izmena API opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="7">
              <v-select
                :items="getDocumentTypes()"
                v-model="updatedApiOptions.documentType"
                item-text="text"
                item-value="value"
                label="Tip dokumenta *"
                required
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Isporučno mesto</v-list-item-title>
                    <v-list-item-subtitle>{{ this.updatedApiOptions.customer.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="6">
                  <v-text-field
                    label="API Url"
                    outlined
                    dense
                    hide-details
                    v-model="updatedApiOptions.baseUrl"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col sm="6">
                  <v-text-field
                    label="Korisničko ime"
                    outlined
                    dense
                    hide-details
                    v-model="updatedApiOptions.username"
                  >
                  </v-text-field>
                </v-col>

                <v-col sm="6">
                  <v-text-field
                    label="Lozinka"
                    outlined
                    dense
                    hide-details
                    v-model="updatedApiOptions.password"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col sm="6">
                  <v-switch
                    label="Aktivan"
                    outlined
                    dense
                    hide-details
                    v-model="updatedApiOptions.active"
                  >
                  </v-switch>
                </v-col>

                <v-col sm="6">
                  <v-switch
                    label="Test"
                    outlined
                    dense
                    hide-details
                    v-model="updatedApiOptions.isTest"
                  >
                  </v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red--text plain ma-2" text @click="onCancel">
          Odustani
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
          Obriši
          <v-icon right>mdi-delete</v-icon>
        </v-btn>
        <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
          Sačuvaj izmene
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da sačuvate promene?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
              Odustani
            </v-btn>
            <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
              Sačuvaj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da obrišete?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmDelete = false">
              Odustani
            </v-btn>
            <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
              Obriši
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from '../api/dispatcherOptions.js';
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: 'EditApiOptionsDialog',
  data: function () {
    return {
      dialogEditApiOptions: false,
      dialogConfirmSave: false,
      dialogConfirmDelete: false,
      puttingToApi: false,
      deleting: false,

      updatedApiOptions: {
        customer: {
          name: null
        },
        documentType: null,
        baseUrl: null,
        username: null,
        password: null,
        active: null,
        isTest: null
      }
    }
  },
  props: {
    propApiOptions: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.updatedApiOptions = this.shallowCopy(this.propApiOptions);
  },
  methods: {
    shallowCopy(originalObj) {
      return JSON.parse(JSON.stringify(originalObj));
    },
    onCancel() {
      this.updatedApiOptions = this.shallowCopy(this.propApiOptions);
      this.dialogEditApiOptions = false;
    },
    onConfirmEdit() {
      this.puttingToApi = true;

      dispatcherOptionsApi.updateApiOptions(this.propApiOptions.id, this.updatedApiOptions, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditApiOptions = false;

          this.$emit('reloadApiOptions');
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },

    onConfirmDelete() {
      this.deleting = true;

      dispatcherOptionsApi.deleteApiOptions(this.propApiOptions.id, this.$store.getters.token)
        .then(() => {
          return null
        })
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogEditApiOptions = false;

          this.$emit('reloadApiOptions');
        })
        .catch(() => {
          this.deleting = false;
        });
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    }
  }
}
</script>
