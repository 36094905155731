<template>
  <v-container class="mt-14">
    <v-row aalign="center" justify="center">
      <v-col sm="6">
        <add-ftp-client-options
          :copiedFtpClientOptions="ftpClientOptions"
          @companySelected="onCompanySelected"/>
      </v-col>

      <v-col sm="8">
        <ftp-client-options-list
          @ftpClientOptionsCopied="passCopiedFtpClientOptions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddFtpClientOptions from "../components/AddFtpClientOptions.vue";
import FtpClientOptionsList from "../components/FtpClientOptionsList.vue";

export default {
  name: "AddFtpClientOptionsPage",
  components: {
    AddFtpClientOptions,
    FtpClientOptionsList
  },
  data() {
    return {
      selectedCompanyId: null,
      ftpClientOptions: null,
    }
  },
  methods: {
    onCompanySelected(compId) {
      this.selectedCompanyId = compId;
    },

    passCopiedFtpClientOptions(ftpOpt) {
      this.ftpClientOptions = ftpOpt;
    },
  }
}
</script>
