<template>
  <div class="ma-3 pa-0">
    <v-dialog v-model="dialogNewCustomer" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" dark v-bind="attrs" v-on="on">
          Dodaj isporučno mesto
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje isporučnog mesta
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field label="Naziv isporučnog mesta *" required outlined v-model="newCustomer.name"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-subheader>Kodovi</v-subheader>
                 <v-divider></v-divider>
              </v-col>

              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="GLN" outlined v-model="newCustomer.gln"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="LGLN" outlined v-model="newCustomer.lgln"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-subheader>Lokacija</v-subheader>
                 <v-divider></v-divider>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field label="Adresa" outlined v-model="newCustomer.address"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
                <v-text-field label="Grad" outlined v-model="newCustomer.town"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
                <v-text-field label="Naselje ID" outlined v-model="newCustomer.naseljeId"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-subheader>Kontakt informacije</v-subheader>
                 <v-divider></v-divider>
              </v-col>

              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Email" outlined v-model="newCustomer.email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Telefon" outlined v-model="newCustomer.telephone"></v-text-field>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field label="Kontakt osoba" outlined v-model="newCustomer.contactPerson"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-subheader>Ostalo</v-subheader>
                 <v-divider></v-divider>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select :items="customerTypes" v-model="newCustomer.typeId" item-text="text" item-value="value" label="Tip isporučnog mesta *" required outlined></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select :items="pairTypes" v-model="newCustomer.pair" item-text="text" item-value="value" label="Tip uparivanja" outlined></v-select>
              </v-col>

              <v-col cols="12" sm="4">
                <v-checkbox v-model="newCustomer.active" label="Aktivan"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="newCustomer.orderable" label="Orderable"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="newCustomer.sendByEmail" label="Slanje na email"></v-checkbox>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-autocomplete :items="swTemplates" v-model="newCustomer.templateSw" item-text="name" item-value="id" label="Template Software *" outlined></v-autocomplete>
              </v-col>
               <v-col cols="12" sm="12" md="6">
                <v-autocomplete :items="software" v-model="newCustomer.softwareId" item-text="name" item-value="id" label="Software ID" outlined></v-autocomplete>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0">
                <v-textarea label="Opis" outlined auto-grow v-model="newCustomer.description"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>* označava neophodna polja</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddCustomer" :loading="postingToApi">
            Dodaj isporučno mesto
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as customersApi from '../api/customers'
import * as utilsApi from '../api/utils'

export default {
  name: "AddCustomerDialog",
  data: function () {
    return {
      dialogNewCustomer: false,
      postingToApi: false,
      newCustomer: {},
      snackbar: false,
      snackbarText: "",
      customerTypes: [
        { value: 1, text: "1 - Kupac" },
        { value: 2, text: "2 - Dobavljač" },
        { value: 3, text: "3 - Budžetski korisnik" }
      ],
      pairTypes: [
        { value: 1, text: "1 - Original" },
        { value: 2, text: "2 - Primalac" },
        { value: 4, text: "4 - AUB" }
      ],
      software: [],
      swTemplates: []
    }
  },
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.newCustomer.typeId = 1;
    this.getSoftware();
    this.getSwTemplates();
  },
  methods: {
    onAddCustomer () {
      // TODO: validacija pre slanja na API

      this.postingToApi = true;
      this.preprocessData();

      customersApi.postCustomer(this.newCustomer, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewCustomer = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Dodato isporučno mesto sa ID-jem: " + responseData.id;
          this.snackbar = true;

          // brisanje unetih podataka za novu kompaniju
          this.newCustomer = {};
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewCustomer = false;
      this.newCustomer = {};
      this.newCustomer.typeId = 1;
    },
    preprocessData () {
      this.newCustomer.companyId = this.companyId;
      this.newCustomer.active = (this.newCustomer.active) ? 1 : 0;
      this.newCustomer.orderable = (this.newCustomer.orderable) ? 1 : 0;
      this.newCustomer.sendByEmail = (this.newCustomer.sendByEmail) ? 1 : 0;
    },
    getSoftware() {
      utilsApi.getSoftware(this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.software = responseData;
        })
        .catch(() => {

        });
    },
    getSwTemplates() {
      utilsApi.getSwTemplates(this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.swTemplates = responseData;
        })
        .catch(() => {

        });
    }
  }
}
</script>
