<template>
  <v-container class="mt-14">
    <v-row aalign="center" justify="center">
      <v-col sm="6">
        <add-email-options
          :copiedEmailOptions="emailOptions"
          :propCustomer="customer"
          @companySelected="onCompanySelected"/>
      </v-col>

      <v-col sm="10">
        <email-options-list
          :companyId="selectedCompanyId"
          @emailOptionsCopied="passCopiedEmailOptions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCustomers from "../api/customers.js";

import AddEmailOptions from "../components/AddEmailOptions.vue";
import EmailOptionsList from "../components/EmailOptionsList.vue";

export default {
  name: "AddEmailOptionsPage",
  components: {
    AddEmailOptions,
    EmailOptionsList
  },
  data() {
    return {
      selectedCompanyId: null,
      emailOptions: null,
      customer: null
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getCustomer(id);
    }
  },
  methods: {
    onCompanySelected(compId) {
      this.selectedCompanyId = compId;
    },

    passCopiedEmailOptions(emailOptions) {
      this.emailOptions = emailOptions;
    },

    getCustomer(id) {
      this.loading = true;

      apiCustomers.getCustomer(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customer = responseData;
          this.selectedCompanyId = this.customer.companyId;
          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.customer = null;
          this.loading = false;
        })
    },
  }
}
</script>
