<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      ID: {{ dispatcherRule.id }}
      <v-spacer></v-spacer>
      <v-chip class="ma-2" :color="getChipColor()" label text-color="white">
        <v-icon left> {{ getChipIcon() }}</v-icon>
        {{ getChipText() }}
      </v-chip>
    </v-card-title>

    <v-card-subtitle>
      Prioritet
      <br />
      <v-rating readonly
        empty-icon="mdi-circle-outline"
        full-icon="mdi-circle"
        v-model="dispatcherRule.priority"
        :length="5"
        color="red lighten-1"
        size="20"
        background-color="grey lighten-1">
      </v-rating>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item v-for="item in dispatcherRuleInfo" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getDispatcherRuleProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-row>
            <v-col sm="6">
              <v-list dense>
                <v-list-item v-for="item in dispatcherRuleCustomersInfo" :key="item.label">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getDispatcherRuleProperty(item.propertyName) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col sm="6">
              <v-list dense>
                <v-list-item v-for="item in dispatcherRuleCompaniesInfo" :key="item.label">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getDispatcherRuleProperty(item.propertyName) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <v-list dense>
                <v-list-item v-for="item in dispatcherRuleOptions" :key="item.label">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getDispatcherRuleProperty(item.propertyName, item.isFlag) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleteDispatcherRuleInProgress">
        Obriši
        <v-icon right>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete pravilo/izuzetak za Dispečera?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" outlined @click="dialogConfirmDelete = false">
            Ne
          </v-btn>
          <v-btn class="error ma-2" text @click="onDeleteDispatcherRule" :loading="deleteDispatcherRuleInProgress">
            Da
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import * as apiDispatcher from "../api/dispatcher.js";
import Snackbar from "../components/Snackbar.vue";
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: 'DispatcherRuleDetails',
  components: {
    Snackbar
  },
  data: function () {
    return {
      loading: false,
      deleteDispatcherRuleInProgress: false,
      dialogConfirmDelete: false,
      dispatcherRuleInfo: [
        { label: "Tip dokumenta", propertyName: 'documentTypeID', icon: "mdi-file-document-outline" },
        { label: "Datum kreiranja", propertyName: 'createdAt', icon: "mdi-sort-calendar-ascending" },
        { label: "Datum ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" },

        { label: "Izlazni format", propertyName: 'outputFormat.name', icon: "mdi-export" },
        { label: "Kanal prenosa", propertyName: 'channelType.description', icon: "mdi-cube-send" },

        { label: "Software", propertyName: 'software.name', icon: "mdi-desktop-classic" }
      ],
      dispatcherRuleCustomersInfo: [
        { label: "Pošiljalac", propertyName: 'sender.name', icon: "mdi-home-export-outline" },
        { label: "Primalac", propertyName: 'receiver.name', icon: "mdi-home-import-outline" },
      ],
      dispatcherRuleCompaniesInfo: [
        { label: "Kompanija pošiljalac", propertyName: 'senderCompany.companyName', icon: "mdi-home-export-outline" },
        { label: "Kompanija primalac", propertyName: 'receiverCompany.companyName', icon: "mdi-home-import-outline" },
      ],
      dispatcherRuleOptions: [
        { label: "Uparivanje", propertyName: "pair", isFlag: false },
        { label: "Slanje jednom dnevno", propertyName: "sendOnceADay", isFlag: true },
        { label: "Resetovanje 'UserProcessed' polja", propertyName: "resetUserProcessed", isFlag: true },
      ],

      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    dispatcherRule: {
      type: Object,
      required: true
    }
  },
  methods: {
    getDispatcherRuleProperty(propertyName, isFlag = false) {
      if (isFlag) {
        let value = this.dispatcherRule[propertyName];
        if (value) {
          return "Da";
        }
        else {
          return "Ne";
        }
      }

      if (propertyName === "documentTypeID") {
        return DocumentTypes.getById(this.dispatcherRule[propertyName]).name;
      }

      let dateProperties = [ "createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDate(this.dispatcherRule[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        if (this.dispatcherRule[firstPart] === null) {
          return "-";
        }

        let secondPart = stringParts[1];

        let firstPartObject = this.dispatcherRule[firstPart];
        return firstPartObject[secondPart];
      }

      return this.dispatcherRule[propertyName];
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    onDeleteDispatcherRule() {
      this.deleteDispatcherRuleInProgress = true;

      apiDispatcher.deleteDispatcherRule(this.dispatcherRule.id, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.deleteDispatcherRuleInProgress = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;

          this.showNotification("Uspešno obrisano pravilo/izuzetak ID: " + this.dispatcherRule.id);

          this.$router.push('/dispatcher');
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.deleteDispatcherRuleInProgress = false;
        });
    },

    getChipColor() {
      if (this.dispatcherRule.priority === 1) {
        return "green";
      }
      else {
        return "orange";
      }
    },
    getChipText() {
      if (this.dispatcherRule.priority === 1) {
        return "Pravilo";
      }
      else {
        return "Izuzetak";
      }
    },
    getChipIcon() {
      if (this.dispatcherRule.priority === 1) {
        return "mdi-alpha-p-circle";
      }
      else {
        return "mdi-alpha-i-circle";
      }
    }
  }
}
</script>
