<template>
  <v-card elevation="4">
    <v-card-title>
      API opcije slanja
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="listOfApiOptions"
    :server-items-length="options.page * options.itemsPerPage + 1"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    }"
    no-data-text="Nema rezultata"
    loading-text="Učitavanje podataka... Molimo sačekajte"
    class="elevation-1 row-pointer"
    width="100%"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ format_datetime(item.createdAt) }}</span>
      </template>

      <template v-slot:[`item.documentType`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ getDocumentTypeIcon(item) }}</v-icon>
          </template>
          <span>{{getDocumentTypeTooltip(item)}}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="showCopyButton === true" small class="mr-2" @click="onCopy(item)">mdi-content-copy</v-icon>

        <api-options-details-dialog
          v-if="showCopyButton === false"
          :propApiOptions="item"
        />

        <edit-api-options-dialog
          v-if="showCopyButton === false"
          :propApiOptions="item"
          @reloadApiOptions="getApiOptionsFromAPI()"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js';
import moment from 'moment';
import DocumentTypes from '../common/documentTypes.js';

import EditApiOptionsDialog from '../components/EditApiOptionsDialog.vue';
import ApiOptionsDetailsDialog from '../components/ApiOptionsDetailsDialog.vue';

export default {
  name: "ApiOptionsList",
  components: {
    EditApiOptionsDialog,
    ApiOptionsDetailsDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      listOfApiOptions: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%", sortable: false },
          { text: 'Isporučno mesto', value: 'customer.name', sortable: false },
          { text: 'Tip dokumenta', value: 'documentType', width: "3%", sortable: false },
          { text: 'API Url', value: 'baseUrl', width: "20%", sortable: false },
          { text: "Korisničko ime", value: 'username', icon: "mdi-account" },
          { text: 'Akcije', value: 'actions', width: "10%", sortable: false }
      ]
    }
  },
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null
    },
    customerId: {
      type: Number,
      required: false,
      default: null
    },
    showCopyButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    companyId: {
      handler() {
        this.getApiOptionsFromAPI();
      }
    },
    options: {
      handler () {
        this.getApiOptionsFromAPI();
      },
      deep: true,
    },
  },
  mounted () {
    this.getApiOptionsFromAPI()
  },
  methods: {
    getApiOptionsFromAPI() {
      this.loading = true;

      this.options.companyId = this.companyId;
      this.options.customerId = this.customerId;
      apiDispatcherOptions.getApiOptions(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfApiOptions = responseData;
        })
        .catch(() => {
          this.listOfApiOptions = [];
          this.loading = false;
        })
    },

    onCopy(apiOptions) {
      this.$emit("apiOptionsCopied", apiOptions);
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },

    getDocumentTypeIcon(apiOptions) {
      return DocumentTypes.getById(apiOptions.documentType).letterIcon;
    },

    getDocumentTypeTooltip(apiOptions) {
      return DocumentTypes.getById(apiOptions.documentType).name;
    }
  }
}
</script>
