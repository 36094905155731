<template>
  <v-card elevation="4">
    <v-card-title>
      FTP opcije slanja
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="listOfFtpClientOptions"
    :server-items-length="options.page * options.itemsPerPage + 1"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    }"
    no-data-text="Nema rezultata"
    loading-text="Učitavanje podataka... Molimo sačekajte"
    class="elevation-1 row-pointer"
    width="100%"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ format_datetime(item.createdAt) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="showCopyButton === true" small class="mr-2" @click="onCopy(item)">mdi-content-copy</v-icon>

        <ftp-client-options-details-dialog
          v-if="showCopyButton === false"
          :propFtpClientOptions="item"
        />

        <edit-ftp-client-options-dialog
          v-if="showCopyButton === false"
          :propFtpClientOptions="item"
          @reloadFtpClientOptions="getFtpClientOptionsFromAPI()"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js';
import moment from 'moment';

import EditFtpClientOptionsDialog from './EditFtpClientOptionsDialog.vue';
import FtpClientOptionsDetailsDialog from './FtpClientOptionsDetailsDialog.vue';

export default {
  name: "FtpClientOptionsList",
  components: {
    EditFtpClientOptionsDialog,
    FtpClientOptionsDetailsDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      listOfFtpClientOptions: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%", sortable: false },
          { text: 'Isp. mesto - klijent', value: 'customerClient.name', sortable: false },
          { text: 'Kompanija - klijent', value: 'companyClient.companyName', sortable: false },
          { text: 'Isp. mesto - server', value: 'customerServer.name', sortable: false },
          { text: 'Host', value: 'host', width: "20%", sortable: false },
          { text: 'Port', value: 'port', width: "10%", sortable: false },
          { text: 'Akcije', value: 'actions', width: "10%", sortable: false }
      ]
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false,
      default: null
    },
    showCopyButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    options: {
      handler () {
        this.getFtpClientOptionsFromAPI();
      },
      deep: true,
    },
  },
  mounted () {
    this.getFtpClientOptionsFromAPI()
  },
  methods: {
    getFtpClientOptionsFromAPI() {
      this.loading = true;

      this.options.customerId = this.customerId;
      apiDispatcherOptions.getFtpClientOptions(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfFtpClientOptions = responseData;
        })
        .catch(() => {
          this.listOfFtpClientOptions = [];
          this.loading = false;
        })
    },

    onCopy(ftpClientOptions) {
      this.$emit("ftpClientOptionsCopied", ftpClientOptions);
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    }
  }
}
</script>
