import * as fetch from './fetch'

///////////////////////////////////////////////////////
//                 Email opcije
///////////////////////////////////////////////////////

export function addEmailOptions(newEmailOptions, token) {
  const endpoint = '/dispatcherOptions/emailOptions';
  return fetch.POST(endpoint, token, newEmailOptions, true);
}

export function addEmailOptionsForCompany(newEmailOptions, token) {
  const endpoint = '/dispatcherOptions/emailOptions/forCompany';
  return fetch.POST(endpoint, token, newEmailOptions, true);
}

export function updateEmailOptions(emailOptionsId, updatedEmailOptions, token) {
  const endpoint = '/dispatcherOptions/emailOptions/' + emailOptionsId;
  return fetch.PUT(endpoint, token, updatedEmailOptions, true);
}

export function getEmailOptions(options, token) {
  let parameters = mapToParameters(options);

  const endpoint = '/dispatcherOptions/emailOptions';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function deleteEmailOptions(id, token) {
  const endpoint = '/dispatcherOptions/emailOptions/' + id;
  return fetch.DELETE(endpoint, token, true);
}

///////////////////////////////////////////////////////
//                 API opcije
///////////////////////////////////////////////////////

export function addApiOptions(newApiOptions, token) {
  const endpoint = '/dispatcherOptions/apiOptions';
  return fetch.POST(endpoint, token, newApiOptions, true);
}

export function addApiOptionsForCompany(newApiOptions, token) {
  const endpoint = '/dispatcherOptions/apiOptions/forCompany';
  return fetch.POST(endpoint, token, newApiOptions, true);
}

export function getApiOptions(options, token) {
  let parameters = mapToParameters(options);

  const endpoint = '/dispatcherOptions/apiOptions';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function updateApiOptions(apiOptionsId, updatedApiOptions, token) {
  const endpoint = '/dispatcherOptions/apiOptions/' + apiOptionsId;
  return fetch.PUT(endpoint, token, updatedApiOptions, true);
}

export function deleteApiOptions(id, token) {
  const endpoint = '/dispatcherOptions/apiOptions/' + id;
  return fetch.DELETE(endpoint, token, true);
}

///////////////////////////////////////////////////////
//                 FileNameFormats
///////////////////////////////////////////////////////

export function getCustomerFilenameFormats(options, token) {
  let parameters = mapToParameters(options);

  const endpoint = '/dispatcherOptions/customerFileNameFormats';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function getFilenameFormats(options, token) {
  let parameters = mapToParameters(options);

  const endpoint = '/dispatcherOptions/fileNameFormats';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function addFileNameFormat(newFileNameFormat, token) {
  const endpoint = '/dispatcherOptions/fileNameFormats';
  return fetch.POST(endpoint, token, newFileNameFormat, true);
}

export function addCustomerFileNameFormat(newCustomerFileNameFormat, token) {
  const endpoint = '/dispatcherOptions/fileNameFormats/connect';
  return fetch.POST(endpoint, token, newCustomerFileNameFormat, true);
}

export function addCompanyFileNameFormat(newCompanyFileNameFormat, token) {
  const endpoint = '/dispatcherOptions/fileNameFormats/connect/byCompany';
  return fetch.POST(endpoint, token, newCompanyFileNameFormat, true);
}

export function deleteCustomerFileNameFormat(customerFileNameFormatId, token) {
  const endpoint = '/dispatcherOptions/customerFileNameFormats/' + customerFileNameFormatId;
  return fetch.DELETE(endpoint, token, true);
}

///////////////////////////////////////////////////////
//                 FtpClientOptions
///////////////////////////////////////////////////////

export function getFtpClientOptions(options, token) {
  let parameters = mapToParameters(options);

  const endpoint = '/dispatcherOptions/ftpClientOptions';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function addFtpOptions(newFtpOptions, token) {
  const endpoint = '/dispatcherOptions/ftpClientOptions';
  return fetch.POST(endpoint, token, newFtpOptions, true);
}

export function updateFtpClientOptions(id, updatedFtpOptions, token) {
  const endpoint = '/dispatcherOptions/ftpClientOptions/' + id;
  return fetch.PUT(endpoint, token, updatedFtpOptions, true);
}

export function deleteFtpClientOptions(ftpOptionsId, token) {
  const endpoint = '/dispatcherOptions/ftpClientOptions/' + ftpOptionsId;
  return fetch.DELETE(endpoint, token, true);
}
///////////////////////////////////////////////////////

function mapToParameters(options) {
  let parameters = {};

  if (options.page !== null) {
    parameters.pageNum = options.page;
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage;
  }

  if (options.companyId) {
    parameters.companyId = options.companyId;
  }

  if (options.customerId) {
    parameters.customerId = options.customerId;
  }

  return parameters;
}
