<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="9">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <company-details v-bind:company="company" v-if="finishedLoading === true" @updateCompany="getCompany"/>
        </v-row>
      </v-col>
      <v-col cols="12" sm="9">
        <v-row column>
           <v-row class="mt-2 mb-2">
            <add-customer-dialog v-if="company !== null" :companyId="company.id" />

            <v-btn class="primary mt-3" @click="openPageToCreateAltRecForCompany">
              Dodaj alt. primaoca kompanije
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-row>
      </v-col>

      <v-col cols="12" sm="9">
        <v-row column>
           <v-row class="mt-2 mb-2">
            <customers-list v-if="company !== null" :companyId="company.id" />
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCompanies from '../api/companies.js'
import CompanyDetails from '../components/CompanyDetails'
import AddCustomerDialog from '../components/AddCustomerDialog'
import CustomersList from '../components/CustomersList'

export default {
  name: "CompanyDetailsPage",
  components: {
    CompanyDetails,
    AddCustomerDialog,
    CustomersList
  },
  data: function () {
    return {
      company: null,
      loading: false,
      finishedLoading: false
    }
  },
  mounted () {
    const id = this.$route.params.id
    this.getCompany(id)
  },
  methods: {
    getCompany(id) {
      this.loading = true

      apiCompanies.getCompany(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.company = responseData;

          this.loading = false
          this.finishedLoading = true
        })
        .catch(() => {
          this.company = null
          console.log("Greska! Nije nadjena kompanija")

          this.loading = false
        })
    },

    openPageToCreateAltRecForCompany() {
      let route = this.$router.resolve({ path: '/altReceivers/create/byCompany/' + this.company.id });
      window.open(route.href, '_blank');
    }
  }
}
</script>
