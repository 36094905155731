<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="11">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <customer-details v-bind:customer="customer" v-if="finishedLoading === true" @updateCustomer="getCustomer"/>
          <v-row>
            <v-col cols="12" md="6">
              <farmalogist-for-customer :customerId="customer.id" v-if="finishedLoading === true" />
            </v-col>
            <v-col cols="12" md="6">
              <local-codes-list v-bind:customer="customer" v-if="finishedLoading === true"/>
            </v-col>
          </v-row>

          <customers-cust-codes-list v-bind:customerId="customer.id" v-if="finishedLoading === true" />
          <v-col cols="12">
             <add-customers-cust-code-dialog :customerSender="customer" v-if="finishedLoading === true" @update="getCustomer"/>
          </v-col>

          <v-col cols="12" md="8">
            <customers-alt-receivers-list :customerId="customer.id" v-if="finishedLoading === true" />
          </v-col>
          <v-col cols="12" v-if="finishedLoading === true">
            <v-btn class="primary" :to="getPathToCreateAltReceiverPage()" >
              Dodaj alternativnog primaoca
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-col>

          <users-list :customerId="customer.id" v-if="finishedLoading === true" />
          <portal-users-list :customerId="customer.id" v-if="finishedLoading === true"/>

          <v-col cols="12" v-if="finishedLoading === true">
            <v-card elevation="4">
              <v-card-actions>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-btn @click="showDocumentsList" class="mt-4">Učitaj dokumente</v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4">
                    <v-switch v-model="showDocumentsAsSender" label="Prikaz poslatih dokumenata" @click="onSwitchShowDocumentsSide">
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>

          <documents-list v-if="showDocuments" :advancedSearch="documentsAdvancedSearch"/>
        </v-row>

        <v-row>
          <v-col sm="6">
            <v-card elevation="4" class="mt-3 mb-5" v-if="finishedLoading === true">
              <v-card-title>
                Opcije slanja
              </v-card-title>
              <v-tabs v-model="currentTab" align-with-title>
                <v-tabs-slider></v-tabs-slider>

                <v-tab v-for="item in tabNames" :key="item">
                  {{ item }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="currentTab">
                <v-tab-item>
                  <v-card flat>
                    <email-options-for-customer  v-if="finishedLoading === true" :customerId="customer.id" />
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <api-options-for-customer  v-if="finishedLoading === true" :customerId="customer.id" />
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <file-name-formats-for-customer v-if="finishedLoading === true" :customerId="customer.id" />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCustomers from '../api/customers.js'
import CustomerDetails from '../components/CustomerDetails'
import FarmalogistForCustomer from '../components/FarmalogistForCustomer'
import CustomersCustCodesList from '../components/CustomersCustCodesList'
import AddCustomersCustCodeDialog from '../components/AddCustomersCustCodeDialog'
import LocalCodesList from '../components/LocalCodesList'
import UsersList from '../components/UsersList'
import PortalUsersList from '../components/PortalUsersList.vue'
import DocumentsList from '../components/DocumentsList.vue'

import EmailOptionsForCustomer from '../components/EmailOptionsForCustomer.vue';
import ApiOptionsForCustomer from '../components/ApiOptionsForCustomer.vue';
import FileNameFormatsForCustomer from '../components/FileNameFormatsForCustomer.vue';

import CustomersAltReceiversList from '../components/CustomersAltReceiversList.vue';

export default {
  name: "CustomerDetailsPage",
  components: {
    CustomerDetails,
    FarmalogistForCustomer,
    CustomersCustCodesList,
    AddCustomersCustCodeDialog,
    LocalCodesList,
    UsersList,
    PortalUsersList,
    DocumentsList,
    EmailOptionsForCustomer,
    ApiOptionsForCustomer,
    FileNameFormatsForCustomer,
    CustomersAltReceiversList
  },
  data: function () {
    return {
      currentTab: null,
      tabNames: [
        'email', 'api', 'nazivi fajlova'
      ],

      customer: null,
      loading: false,
      finishedLoading: false,

      showDocuments: false,
      showDocumentsAsSender: true,
      documentsAdvancedSearch: {
        sender: {},
        receiver: {}
      }
    }
  },
  mounted () {
    const id = this.$route.params.id;
    this.getCustomer(id);
  },
  methods: {
    getCustomer(id) {
      this.loading = true;

      apiCustomers.getCustomer(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customer = responseData;

          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.customer = null;
          console.log("Greska! Nije nadjen customer");

          this.loading = false;
        })
    },

    onSwitchShowDocumentsSide() {
      if (this.showDocumentsAsSender) {
        this.documentsAdvancedSearch.sender = this.customer;
        this.documentsAdvancedSearch.receiver = null;
      }
      else {
        this.documentsAdvancedSearch.receiver = this.customer;
        this.documentsAdvancedSearch.sender = null;
      }
    },

    showDocumentsList() {
      this.showDocuments = true;
      this.onSwitchShowDocumentsSide();
    },

    getPathToCreateAltReceiverPage() {
      return '/altReceivers/create/' + this.customer.id;
    }
  }
}
</script>
