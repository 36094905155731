<template>
  <v-row>
    <v-col sm="12">
      <v-card v-if="loading === false" width="100%" class="mt-4" elevation="0">
        <v-card-title>
          API opcije
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text v-if="apiOptionsList.length > 0">
          <v-list dense>
            <v-list-item v-for="item in apiOptionsList" :key="item.id" class="elevation-2 mb-1">
              <v-list-item-icon>
                <v-icon>mdi-api</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Tip dokumenta: {{ getDocumentType(item.documentType) }}</v-list-item-title>
                <v-list-item-subtitle>API Url: {{ item.baseUrl }}</v-list-item-subtitle>
                <v-list-item-subtitle>Username: {{ item.username }}</v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-action>
                <api-options-details-dialog
                  v-if="showCopyButton === false"
                  :propApiOptions="item"
                />
              </v-list-item-action>
              <v-list-item-action>
                <edit-api-options-dialog
                  v-if="showCopyButton === false"
                  :propApiOptions="item"
                  @reloadApiOptions="getApiOptionsFromAPI()"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else>
          Nema podataka.
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary" :to="getToPath()" >
            Dodaj API opcije
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js'
import DocumentTypes from '../common/documentTypes.js';

import ApiOptionsDetailsDialog from '../components/ApiOptionsDetailsDialog.vue';
import EditApiOptionsDialog from '../components/EditApiOptionsDialog.vue';

export default {
  name: "ApiOptionsForCustomer",
  components: {
    ApiOptionsDetailsDialog,
    EditApiOptionsDialog
  },
  data: function () {
    return {
      loading: false,
      showCopyButton: false,
      apiOptionsList: [],
      farmalogistToUpdate: {},
      options: {
        page: 1,
        itemsPerPage: 10
      }
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getApiOptionsFromAPI();
      }
    }
  },
  mounted () {
    this.getApiOptionsFromAPI()
  },
  methods: {
    getApiOptionsFromAPI() {
      this.loading = true;

      this.options.customerId = this.customerId;
      apiDispatcherOptions.getApiOptions(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.apiOptionsList = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.apiOptionsList = [];
          this.loading = false;
        })
    },
    getDocumentType(documentTypeId) {
      return DocumentTypes.getById(documentTypeId).name;
    },
    getToPath() {
      return "/sendoptions/api/create/" + this.customerId;
    }
  }
}
</script>
