<template>
  <v-container>
    <v-card>
      <v-card-title>
        Korisnici portala
        <v-spacer></v-spacer>
        <v-text-field
          v-model="queryString"
          append-icon="mdi-magnify"
          label="Pretraga"
          single-line
          hide-details
          clearable
          @keyup.enter.prevent='onEnterSearch'
          @click:clear='onClearSearch()'
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listOfPortalUsers"
        :server-items-length="options.page * options.itemsPerPage + 1"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Broj redova po stranici:'
        }"
        no-data-text="Nema rezultata"
        loading-text="Učitavanje podataka... Molimo sačekajte"
        class="elevation-1 row-pointer"
        width="100%"
        @click:row="onRowClick"
      >
        <template v-slot:[`item.active`]="{ item }">
          <v-avatar :color="getAvatarColor(item)" size="15"></v-avatar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiPortalUsers from '../api/portalusers.js'
export default {
  name: "PortalUsersList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true],
        customerId: null
      },
      queryString: null,
      listOfPortalUsers: [],
      headers: [
          { text: 'ID', value: 'id', widht: "10%" },
          { text: 'Portal', value: 'portalParameters.portalName', width: "15%", sortable: false },
          { text: 'Korisničko ime', value: 'username', width: "15%" },
          { text: 'Kompanija', value: 'company.companyName', sortable: false },
          { text: 'PIB', value: 'company.pib', sortable: false },
          { text: 'Uloga', value: 'role' },
          { text: 'Aktivan', value: 'active' },
      ]
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.options.customerId = this.customerId;
        this.getPortalUsersFromAPI();
      },
      deep: true,
    }
  },
  mounted () {
    this.getPortalUsersFromAPI();
  },
  methods: {
    getPortalUsersFromAPI() {
      this.loading = true;

      apiPortalUsers.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfPortalUsers = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfPortalUsers = [];
          this.loading = false;
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString;
      this.options.page = 1;
      this.getPortalUsersFromAPI();
    },

    onClearSearch() {
      this.options.search = '';
      this.queryString = '';
      this.getPortalUsersFromAPI();
    },

    onRowClick() {
    },

    getAvatarColor(item) {
      if (item.active === 1)
        return "success";
      else
        return "error";
    },
  }
}
</script>
