<template>
  <v-dialog v-model="dialogDetailsEmailOptions" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
    </template>
    <v-card v-if="propEmailOptions !== null">
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Detalji Email opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-row>
          <v-col>
            <v-list dense>
              <v-list-item v-for="item in emailOptionsProperties" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getEmailOptionsProperty(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col cols="12" sm="12">
            <div class="body-2" >
              Subject
            </div>
            <div class="body-1 font-weight-regular black--text" >
              {{propEmailOptions.subject}}
            </div>
          </v-col>

          <v-col>
            <div class="body-2" >
              Body
            </div>
            <div class="body-1 font-weight-regular black--text" v-for="(emailBodyPart , index) in getEmailBodyList()" :key="index">
              {{ emailBodyPart }}
            </div>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import DocumentTypes from "../common/documentTypes.js";

export default {
  name: 'EmailOptionsDetailsDialog',
  data: function () {
    return {
      dialogDetailsEmailOptions: false,
      emailOptionsProperties: [
        { label: "Tip dokumenta", propertyName: 'documentType', icon: "mdi-file-document-outline" },
        { label: "Isporučno mesto", propertyName: 'customer.name', icon: "mdi-map-marker" },
        { label: "Datum kreiranja", propertyName: 'createdAt', icon: "mdi-sort-calendar-ascending" },
        { label: "Datum ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ]
    }
  },
  props: {
    propEmailOptions: {
      type: Object,
      required: true
    }
  },
  methods: {
    onCancel() {
      this.dialogDetailsEmailOptions = false;
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    getEmailOptionsProperty(propertyName) {
      if (propertyName === "documentType") {
        return DocumentTypes.getById(this.propEmailOptions.documentType).name;
      }

      let dateProperties = ["createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDate(this.propEmailOptions[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let customer = this.propEmailOptions[firstPart];
        return customer[secondPart];
      }

      return this.propEmailOptions[propertyName];
    },
    getEmailBodyList() {
      return this.propEmailOptions.body.split('\n');
    }
  }
}
</script>
