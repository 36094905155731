import * as fetch from './fetch';

export function getSoftware(token) {
  const endpoint = '/utils/software';
  return fetch.GET(endpoint, token, true);
}


export function getSwTemplates(token) {
  const endpoint = '/utils/swtemplates';
  return fetch.GET(endpoint, token, true);
}

export function getOutputFormats(token) {
  const endpoint = '/utils/outputFormats';
  return fetch.GET(endpoint, token, true);
}

export function getChannelTypes(token) {
  const endpoint = '/utils/channelTypes';
  return fetch.GET(endpoint, token, true);
}
