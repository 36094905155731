<template>
  <v-dialog v-model="dialogDetailsFtpOptions" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
    </template>
    <v-card v-if="propFtpClientOptions !== null">
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Detalji FTP opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-row>
          <v-col>
            <v-list dense>
              <v-list-item v-for="item in ftpClientOptionsProperties" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getFtpClientOptionsPropertyValue(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: 'FtpClientOptionsDetailsDialog',
  data: function () {
    return {
      dialogDetailsFtpOptions: false,
      ftpClientOptionsProperties: [
        { label: "Kompanija - Klijent", propertyName: 'companyClient.companyName', icon: "mdi-export" },
        { label: "Isporučno mesto - Klijent", propertyName: 'customerClient.name', icon: "mdi-export" },
        { label: "Isporučno mesto - Server", propertyName: 'customerServer.name', icon: "mdi-import" },
        { label: "Host", propertyName: 'host', icon: "mdi-server" },
        { label: "Port", propertyName: 'port', icon: "mdi-usb-port" },
        { label: "Folder", propertyName: 'directory', icon: "mdi-folder" },
        { label: "Username", propertyName: 'username', icon: "mdi-account" },
        { label: "Password", propertyName: 'password', icon: "mdi-key" },
        { label: "Datum kreiranja", propertyName: 'createdAt', icon: "mdi-sort-calendar-ascending" },
        { label: "Datum ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ]
    }
  },
  props: {
    propFtpClientOptions: {
      type: Object,
      required: true
    }
  },
  methods: {
    onCancel() {
      this.dialogDetailsFtpOptions = false;
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    getFtpClientOptionsPropertyValue(propertyName) {
      let dateProperties = ["createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDate(this.propFtpClientOptions[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let firstPartEntity = this.propFtpClientOptions[firstPart];
        if (firstPartEntity === null) {
          return "-";
        }
        return firstPartEntity[secondPart];
      }

      return this.propFtpClientOptions[propertyName];
    }
  }
}
</script>
