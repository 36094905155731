<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-card>
            <v-card-title> Dodavanje izuzetka </v-card-title>

            <v-card-text class="mt-4">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="7">
                    <v-select
                      :items="getDocumentTypes()"
                      v-model="newException.documentTypeID"
                      item-text="text"
                      item-value="value"
                      label="Tip dokumenta *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-radio-group
                      v-model="isCompanyBasedException"
                      row
                    >
                     <v-row>
                       <v-col cols="12" sm="6">
                         <v-radio :value="true" label="Izuzetak na nivou kompanija">
                        </v-radio>
                       </v-col>

                       <v-col cols="12" sm="6">
                          <v-radio :value="false" label="Izuzetak na nivou isporučnih mesta">
                          </v-radio>
                       </v-col>
                     </v-row>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-row :justify="getExceptionTypeJustifyValue()">
                      <v-col v-if="isCompanyBasedException" cols="12" sm="6">
                        <v-row>
                          <v-col cols="12" sm="12">
                            <find-company-dialog
                              buttonText="Kompanija pošiljalac"
                              toolbarText="Izaberite kompaniju pošiljaoca"
                              :isSmall="false"
                              icon="mdi-magnify"
                              @update="senderCompanySelected"
                            />
                          </v-col>

                          <v-col cols="12" sm="12">
                            Pošiljalac: {{ this.senderCompany.companyName }}
                          </v-col>

                          <v-col cols="12" sm="12">
                            <find-company-dialog
                              buttonText="Kompanija primalac"
                              toolbarText="Izaberite kompaniju primaoca"
                              :isSmall="false"
                              icon="mdi-magnify"
                              @update="receiverCompanySelected"
                            />
                          </v-col>

                          <v-col cols="12" sm="12">
                            Primalac: {{ this.receiverCompany.companyName }}
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col v-else cols="12" sm="6">
                        <v-row>
                          <v-col cols="12" sm="12">
                            <find-customer-dialog
                              buttonText="Isporučno mesto pošiljalac"
                              toolbarText="Izaberite isporučno mesto pošiljaoca"
                              icon="mdi-magnify"
                              @update="senderSelected"
                            />
                          </v-col>

                          <v-col cols="12" sm="12">
                            Pošiljalac: {{ this.sender.name }}
                          </v-col>

                          <v-col cols="12" sm="12">
                            <find-customer-dialog
                              buttonText="Isporučno mesto primalac"
                              toolbarText="Izaberite isporučno mesto primaoca"
                              icon="mdi-magnify"
                              @update="receiverSelected"
                            />
                          </v-col>

                          <v-col cols="12" sm="12">
                            Primalac: {{ this.receiver.name }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="outputFormats"
                      v-model="newException.outputFormatID"
                      item-text="name"
                      item-value="id"
                      label="Izlazni format *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="channelTypes"
                      v-model="newException.channelID"
                      item-text="description"
                      item-value="id"
                      label="Kanal prenosa *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="pairTypes"
                      v-model="newException.pair"
                      item-text="text"
                      item-value="value"
                      label="Tip uparivanja *"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="newException.sendOnceADay"
                      label="Slanje jednom dnevno"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="newException.resetUserProcessed"
                      label="Resetovanje 'UserProcessed' polja"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              <small>* označava neophodna polja</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="primary ma-2 white--text"
                text
                @click="onAddDispatcherException"
                :loading="postingToApi"
              >
                Dodaj izuzetak
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as dispatcherApi from "../api/dispatcher";
import * as utilsApi from "../api/utils";
import DocumentTypes from "../common/documentTypes.js";

import FindCompanyDialog from "../components/FindCompanyDialog.vue";
import FindCustomerDialog from "../components/FindCustomerDialog.vue";
import Snackbar from "../components/Snackbar.vue"

export default {
  name: "AddDispatcherExceptionPage",
  components: {
    FindCompanyDialog,
    FindCustomerDialog,
    Snackbar
  },
  data: function () {
    return {
      postingToApi: false,
      newException: {},

      isCompanyBasedException: true,

      pairTypes: [
        { value: 1, text: "1 - Original" },
        { value: 2, text: "2 - Primalac" },
        { value: 4, text: "4 - AUB" },
      ],

      channelTypes: [],
      outputFormats: [],
      software: [],

      sender: {
        name: null
      },
      receiver: {
        name: null
      },
      senderCompany: {
        companyName: null
      },
      receiverCompany: {
        companyName: null
      },

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  mounted() {
    this.getSoftware();
    this.getChannelTypes();
    this.getOutputFormats();
  },
  methods: {
    onAddDispatcherException() {
      this.postingToApi = true;

      dispatcherApi
        .addException(this.newException, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodat izuzetak. ID: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    getSoftware() {
      utilsApi
        .getSoftware(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.software = responseData;
        })
        .catch(() => {});
    },
    getChannelTypes() {
      utilsApi
        .getChannelTypes(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.channelTypes = responseData;
        })
        .catch(() => {});
    },
    getOutputFormats() {
      utilsApi
        .getOutputFormats(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.outputFormats = responseData;
        })
        .catch(() => {});
    },

    getExceptionTypeJustifyValue () {
      if (this.isCompanyBasedException) {
        return "start";
      }
      else {
        return "end";
      }
    },

    senderSelected(customer) {
      this.sender = customer;
      this.newException.senderID = customer.id;
    },

    receiverSelected(customer) {
      this.receiver = customer;
      this.newException.receiverID = customer.id;
    },

    senderCompanySelected(company) {
      this.senderCompany = company;
      this.newException.senderCompanyID = company.id;
    },

    receiverCompanySelected(company) {
      this.receiverCompany = company;
      this.newException.receiverCompanyID = company.id;
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    }
  },
};
</script>
