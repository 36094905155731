<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <dispatcher-rule-details :dispatcherRule="dispatcherRule" v-if="dispatcherRule !== null"/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiDispatcher from '../api/dispatcher.js';
import DispatcherRuleDetails from '../components/DispatcherRuleDetails.vue';

export default {
  name: "DispatcherRuleDetailsPage",
  components: {
    DispatcherRuleDetails
  },
  data: function () {
    return {
      dispatcherRule: null,
      loading: false
    }
  },
  mounted () {
    const id = this.$route.params.id;
    this.getDispatcherRule(id);
  },
  methods: {
    getDispatcherRule(id) {
      this.loading = true;

      apiDispatcher.getByID(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.dispatcherRule = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.dispatcherRule = null;
          this.loading = false;
        })
    }
  }
}
</script>
