<template>
  <v-card v-if="company !== null">
    <v-card-title> Dodavanje alternativnih primaoca </v-card-title>

    <v-card-text class="mt-0">
      <v-container>
        <v-row>
          <v-col sm="12">
            <div class="text-subtitle-1">
              Kompanija: {{company.companyName}}
            </div>
          </v-col>

          <v-col cols="12" sm="7">
            <v-row>
              <v-select
                :items="getDocumentTypes()"
                v-model="newCustomerAltReceiver.documentTypeId"
                item-text="text"
                item-value="value"
                label="Tip dokumenta *"
                required
                outlined
                dense
                hide-details
                class="ml-2"
                v-on:change="addDocumentTypeToList"
              ></v-select>
              <v-col sm="9">
                <v-list dense>
                  <v-list-item v-for="(documentType, index) in this.selectedDocumentTypes" :key='index'>
                    <v-list-item-content>
                      <v-list-item-title>{{ documentType.name }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon @click="removeDocumentTypeFromList(index)">
                        mdi-close
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="alternativeCustomerSelected"
                  :companyId="company.id"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi alternativnog primaoca"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučna mesta - Alternativni primaloci</v-list-item-title>

                      <v-list-item-subtitle v-if="this.selectedAlternativeReceivers.length === 0">Nema izabranih isporučnih mesta.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col sm="6">
                <v-list dense v-if="this.selectedAlternativeReceivers.length > 0">
                  <v-list-item v-for="(altRec, index) in this.selectedAlternativeReceivers" :key='index'>
                    <v-list-item-content>
                      <v-list-item-title>{{ altRec.name }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon @click="removeAltRecFromList(index)">
                        mdi-close
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox
              label="Slanje samo alternativnom primaocu"
              v-model="newCustomerAltReceiver.altCustomerOnly"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <small>* označava neophodna polja</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary ma-2 white--text"
        text
        @click="onAddAltReceivers"
        :loading="postingToApi"
      >
        Dodaj
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
  </v-card>
</template>

<script>
import * as customersApi from "../api/customers.js";
import DocumentTypes from "../common/documentTypes.js";

import FindCustomerDialog from "./FindCustomerDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddAltReceiversForCompany",
  components: {
    Snackbar,
    FindCustomerDialog
  },
  data: function () {
    return {
      postingToApi: false,
      newCustomerAltReceiver: {
        documentTypeId: null,
        originalReceiverId: null,
        alternativeReceiverId: null,
        altCustomerOnly: false
      },

      selectedAlternativeReceivers: [],
      selectedDocumentTypes: [],

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  watch: {
    propAlternativeReceiver: {
      handler() {
        this.selectedAlternativeCustomer = this.propAlternativeReceiver;
      }
    }
  },
  props: {
    company: {
      type: Object,
      default: null,
      required: false
    },
  },
  methods: {
    onAddAltReceivers() {
      this.postingToApi = true;

      let toPost = this.prepareForPost();

      customersApi
        .addNewAltReceiversForCompany(toPost, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Broj uspešno dodatih veza između isporučnih mesta: " + responseData.length);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    alternativeCustomerSelected(customer) {
      this.selectedAlternativeReceivers.push(customer);
    },

    removeAltRecFromList(index) {
      if (index < 0) {
        return;
      }

      this.selectedAlternativeReceivers.splice(index, 1);
    },

    addDocumentTypeToList(docTypeId) {
      let documentType = DocumentTypes.getById(docTypeId);
      this.selectedDocumentTypes.push(documentType);
    },

    removeDocumentTypeFromList(index) {
      if (index < 0) {
        return;
      }

      this.selectedDocumentTypes.splice(index, 1);
    },

    prepareForPost() {
      let toPost = {
        documentTypeIds: this.selectedDocumentTypes.map(dt => dt.id),
        altReceiverIds: this.selectedAlternativeReceivers.map(altRec => altRec.id),
        altCustomerOnly: this.altCustomerOnly,
        companyId: this.company.id
      }

      return toPost;
    }
  },
};
</script>
