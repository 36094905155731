export default class FileNamingTemplates {
  static getAllowedFileNamingTemplates() {
    let allowedFileNamingTemplates = [
      //
      { value: "{idFajla}", description: "ID fajla"},
      { value: "{nazivFajla}", description: "Naziv fajla"},

      //
      { value: "{idDokumenta}", description: "ID dokumenta"},
      { value: "{brojDokumenta}", description: "Broj dokumenta"},
      { value: "{datumDokumenta}", description: "Datum dokumenta"},
      { value: "{datumValute}", description: "Datum valute"},
      { value: "{datumKreiranja}", description: "Datum kreiranja"},
      { value: "{datumDanas}", description: "Današnji datum"},

      //
      { value: "{nazivPosiljaoca}", description: "Naziv pošiljaoca"},
      { value: "{nazivPrimaoca}", description: "Naziv primaoca"},

      //
      { value: "{nazivKompanijePosiljaoca}", description: "Naziv kompanije pošiljaoca"},
      { value: "{nazivKompanijePrimaoca}", description: "Naziv kompanije primaoca"},
      { value: "{pibKompanijePosiljaoca}", description: "PIB kompanije pošiljaoca"},
      { value: "{pibKompanijePrimaoca}", description: "PIB kompanije primaoca"},

      //
      { value: "{ccc.GlnDobavljac}", description: "Kod veze - GLN Dobavljač"},
      { value: "{ccc.GlnKupac}", description: "Kod veze - GLN Kupac"},
      { value: "{ccc.GlnPrimalac}", description: "Kod veze - GLN Primalac"},
      { value: "{ccc.customerCode}", description: "Kod veze"},

      //
      { value: "{lokalniKodPosiljaoca}", description: "Lokalni kod pošiljaoca"},
      { value: "{lokalniKodPrimaoca}", description: "Lokalni kod primaoca"},

      //
      { value: "{idRefDokumenta}", description: "ID referenciranog dokumenta"},
      { value: "{brojRefDokumenta}", description: "Broj referenciranog dokumenta"},
    ];

    return allowedFileNamingTemplates;
  }
}
