<template>
  <v-dialog v-model="dialogAddFileNameFormat" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on">
        Dodaj šablon
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Dodavanje šablona za nazive fajlova
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>


      <v-card-text class="mt-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Šablon *"
                outlined
                dense
                hide-details
                v-model="newFileNameFormat.template"
                ref="templateTextField"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <!-- <v-chip-group> -->
              <v-chip
                class="ma-1"
                v-for="(templatePart, index) in getFileNamingTemplates()"
                :key="index"
              >
                {{templatePart.description}}
                <v-icon small right @click="onChipAddPart(templatePart.value)">
                  mdi-plus
                </v-icon>
                <v-icon small right @click="onChipDeletePart(templatePart.value)">
                  mdi-close
                </v-icon>
              </v-chip>
            <!-- </v-chip-group> -->
          </v-row>
        </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="error ma-2 white--text" text @click="onCancel">
          Odustani
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          class="primary ma-2 white--text"
          text
          @click="onAddFileNameFormat"
          :loading="postingToApi"
        >
          Dodaj
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";
import FileNamingTemplates from "../common/fileNamingTemplates.js";

export default {
  name: "AddFileNameFormatDialog",
  components: {
  },
  data: function () {
    return {
      postingToApi: false,
      newFileNameFormat: {
        template: null
      },
      dialogAddFileNameFormat: false,

      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    };
  },
  methods: {
    onAddFileNameFormat() {
      this.postingToApi = true;

      dispatcherOptionsApi
        .addFileNameFormat(this.newFileNameFormat, this.$store.getters.token)
        .then((response) => response.json())
        .then(() => {
          // loading na dugmetu
          this.postingToApi = false;

          this.dialogAddFileNameFormat = false;
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogAddFileNameFormat = false;
      this.newFileNameFormat = {};
    },
    getFileNamingTemplates() {
      return FileNamingTemplates.getAllowedFileNamingTemplates();
    },
    onChipAddPart(templatePartToAdd) {
      let textFieldElement = this.$refs.templateTextField;
      let inputElement = textFieldElement.$el.querySelector("input");

      let cursorStartPosition = inputElement.selectionStart;
      let cursorEndPosition = inputElement.selectionEnd;

      let currentTemplate = this.newFileNameFormat.template;
      if (currentTemplate === null ||
          currentTemplate === undefined ||
          currentTemplate.length === 0) {
        this.newFileNameFormat.template = templatePartToAdd;
        return;
      }

      let newTemplate = currentTemplate.substring(0, cursorStartPosition)
        + templatePartToAdd
        + currentTemplate.substring(cursorEndPosition, currentTemplate.length);

      this.newFileNameFormat.template = newTemplate;
    },
    onChipDeletePart(templatePartToDelete) {
      if (this.newFileNameFormat.template === null || this.newFileNameFormat.template.length === 0) {
        return;
      }

      this.newFileNameFormat.template = this.newFileNameFormat.template.replaceAll(templatePartToDelete, "");
    }
  },
};
</script>
