<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8">
        <v-row align-center column justify-center>
          <v-card width="80%" class="mb-6">
            <v-card-title> Dodavanje korisnika portala </v-card-title>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="5" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Kompanija:</span>
                  {{ selectedCompany.companyName }}
                </v-col>
                <v-col sm="2">
                  <find-company-dialog
                    @update="companySelected"
                    icon="mdi-domain"
                    buttonText="Izaberite"
                    toolbarText="Biranje kompanije"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="5" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Portal:</span>
                  {{ selectedPortal.portalName }}
                </v-col>
                <v-col sm="2">
                  <find-portal-dialog
                    @update="portalSelected"
                    icon="mdi-monitor"
                    buttonText="Izaberite"
                    toolbarText="Biranje portala"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="5" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Centralni korisnik:</span>
                  {{ selectedUser.username }}
                </v-col>
                <v-col sm="2">
                  <find-user-dialog
                    @update="userSelected"
                    icon="mdi-account"
                    buttonText="Izaberite"
                    toolbarText="Biranje centralnog korisnika"
                    :disabled="selectedCompany.id === null"
                    :companyId="selectedCompany.id"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-text>
              <v-row class="mt-2">
                <v-col sm="12" md="6">
                  <v-row>
                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Korisničko ime" required outlined
                        v-model="newPortalUser.username"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-select v-model="newPortalUser.role"
                        :items="roles" item-text="text" item-value="value"
                        label="Uloga" required outlined>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error ma-2 white--text" text @click="onCancel">
                Odustani
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn class="primary ma-2 white--text" text @click="onAddUser" :loading="postingToApi">
                Dodaj korisnika portala
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
          </snackbar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiPortalUsers from "../api/portalusers";
import FindCompanyDialog from "../components/FindCompanyDialog.vue";
import FindPortalDialog from "../components/FindPortalDialog.vue";
import FindUserDialog from "../components/FindUserDialog.vue";
import Snackbar from "../components/Snackbar.vue";

export default {
  name: "PortalUserAddPage",
  components: {
    FindCompanyDialog,
    FindPortalDialog,
    FindUserDialog,
    Snackbar
  },
  data: function () {
    return {
      newPortalUser: {},
      postingToApi: false,

      selectedCompany: {
        id: null
      },
      selectedPortal: {},
      selectedUser: {},

      snackbar: {
        show: false,
        text: "",
        isError: false
      },

      roles: [
        { value: 2, text: "2 - Podrazumevano" },
        { value: 3, text: "3 - Dobavljač" },
      ],
    };
  },
  methods: {
    onAddUser() {
      // TODO: validacija pre slanja na API
      this.postingToApi = true;

      this.newPortalUser.companyId = this.selectedCompany.id;
      this.newPortalUser.portalId = this.selectedPortal.id;
      this.newPortalUser.userId = this.selectedUser.id;

      apiPortalUsers
        .postUser(this.newPortalUser, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          this.showNotification("Dodat korisnik portala: " + responseData.username);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    onCancel() {
      this.$router.push('/users');
    },
    companySelected(company) {
      this.selectedCompany = company;
    },
    portalSelected(portal) {
      this.selectedPortal = portal;
    },
    userSelected(user) {
      this.selectedUser = user;
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
  },
};
</script>
