<template>
  <v-container>
    <v-card>
      <v-card-title>
        Pravila i izuzeci
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
      :headers="headers"
      :items="listOfDispatcherRules"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ format_date(item.createdAt) }}</span>
        </template>

        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{ format_date(item.updatedAt) }}</span>
        </template>

        <template v-slot:[`item.receiverCompany`]="{ item }">
          <span>{{ getCompanyInfo(item.receiverCompany) }}</span>
        </template>

        <template v-slot:[`item.senderCompany`]="{ item }">
          <span>{{ getCompanyInfo(item.senderCompany) }}</span>
        </template>

        <template v-slot:[`item.receiver`]="{ item }">
          <span>{{ getCustomerInfo(item.receiver) }}</span>
        </template>

        <template v-slot:[`item.sender`]="{ item }">
          <span>{{ getCustomerInfo(item.sender) }}</span>
        </template>

        <template v-slot:[`item.documentTypeID`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">{{ getDocumentType(item.documentTypeID).letterIcon }}</v-icon>
            </template>
            <span>{{ getDocumentType(item.documentTypeID).name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
    </v-card>
  </v-container>
</template>

<script>
import * as apiDispatcher from '../api/dispatcher.js'
import moment from 'moment'
import Snackbar from './Snackbar.vue'
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: "DispatcherRulesList",
  components: {
    Snackbar
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      listOfDispatcherRules: [],
      headers: [
          { text: 'ID', value: 'id', width: "4%" },
          { text: 'Prioritet', value: 'priority', width: "4%", sortable: false },
          { text: 'Tip dokumenta', value: 'documentTypeID', width: "3%", sortable: false },
          { text: 'Softver', value: 'software.name', width: "5%", sortable: false },
          { text: 'Kompanija pošiljalac', value: 'senderCompany', width: "12%" },
          { text: 'Kompanija primalac', value: 'receiverCompany', width: "12%" },
          { text: 'Pošiljalac', value: 'sender', width: "10%" },
          { text: 'Primalac', value: 'receiver', width: "10%" },
          { text: 'Format', value: 'outputFormat.name', width: "10%" },
          { text: 'Kanal prenosa', value: 'channelType.description', width: "10%" },
          { text: 'Datum kreiranja', value: 'createdAt', width: "9%" },
          { text: 'Datum ažuriranja', value: 'updatedAt', width: "9%" },
      ],

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    }
  },
  props: {
    advancedSearch: {
      type: Object,
      required: false
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getDispatcherRulesFromAPI()
      },
      deep: true,
    },
    advancedSearch: {
      handler(newAdvancedSearch) {
        this.advancedSearch = newAdvancedSearch;
        this.getDispatcherRulesFromAPI();
      },
      deep: true
    }
  },
  mounted () {
    this.getDispatcherRulesFromAPI();
  },
  methods: {
    getDispatcherRulesFromAPI() {
      this.loading = true

      apiDispatcher.getList(this.options, this.advancedSearch, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfDispatcherRules = responseData;
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.loading = false;
        });
    },

    onRowClick(row) {
      let route = this.$router.resolve({ path: '/dispatcher/details/' + row.id });
      window.open(route.href, '_blank');
    },

    format_date(value){
      if (value) {
        let dateString = moment(String(value)).format('DD.MM.YYYY');
        if (dateString === "01.01.0001") {
          return null;
        }
        else {
          return dateString;
        }
      }
    },

    getCompanyInfo(company) {
      if (company == null) {
        return "";
      }

      return company.companyName;
    },

    getCustomerInfo(customer) {
      if (customer == null) {
        return "";
      }

      return customer.name;
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },

    getDocumentType(id) {
      return DocumentTypes.getById(id);
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
