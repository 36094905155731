<template>
<v-container fluid>
  <v-card class="mb-3" elevation="4">
      <!-- <v-card-title>
        Napredna pretraga
      </v-card-title> -->
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" md="3">
            <v-select dense :items="getDocumentTypes()" v-model="advancedSearch.docType" item-text="text" item-value="value" label="Tip dokumenta" outlined>
            </v-select>

            <v-select dense :items="software" v-model="advancedSearch.softwareID" item-text="name" item-value="id" label="Software" outlined>
            </v-select>
          </v-col>

          <v-col cols="12" md="3" class="pl-6 pr-0">
            <v-row class="mt-1">
              <v-col cols="12" sm="3" class="ma-0 pa-0">
                <find-customer-dialog
                  @update="senderSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto pošiljaoca"
                />
              </v-col>
              <v-col cols="12" sm="9" class="pt-0">
                <v-list class="py-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-0">
                      <v-list-item-title>{{ advancedSearch.sender.name }}</v-list-item-title>
                      <v-list-item-subtitle>Pošiljalac</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col cols="12" sm="3" class="ma-0 pa-0">
                <find-customer-dialog
                @update="receiverSelected"
                :isSmall="true"
                icon="mdi-magnify"
                buttonText=""
                toolbarText="Izaberi isporučno mesto primaoca"
                />
              </v-col>
              <v-col cols="12" sm="9" class="pt-0">
                <v-list class="py-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-0">
                      <v-list-item-title>{{ advancedSearch.receiver.name }}</v-list-item-title>
                      <v-list-item-subtitle>Primalac</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3" class="pl-6 pr-0">
            <v-row class="mt-1">
              <v-col cols="12" sm="3" class="ma-0 pa-0">
                <find-company-dialog
                  @update="senderCompanySelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi kompaniju pošiljaoca"
                />
              </v-col>
              <v-col cols="12" sm="9" class="pt-0">
                <v-list class="py-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-0">
                      <v-list-item-title>{{ advancedSearch.senderCompany.companyName }}</v-list-item-title>
                      <v-list-item-subtitle>Kompanija pošiljalac</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col cols="12" sm="3" class="ma-0 pa-0">
                <find-company-dialog
                @update="receiverCompanySelected"
                :isSmall="true"
                icon="mdi-magnify"
                buttonText=""
                toolbarText="Izaberi kompaniju primaoca"
                />


              </v-col>
              <v-col cols="12" sm="9" class="pt-0">
                <v-list class="py-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-0">
                      <v-list-item-title>{{ advancedSearch.receiverCompany.companyName }}</v-list-item-title>
                      <v-list-item-subtitle>Kompanija primalac</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-row>
              <v-col>
                <v-row>
                  <v-col sm="12">
                    <v-slider
                      v-model="advancedSearch.priority"
                      :tick-labels="prioritiesSliderTicksLabels"
                      :min="0"
                      :max="5"
                      step="1"
                      ticks="always"
                      tick-size="1"
                      label="Prioritet"
                    ></v-slider>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="py-0 my-0">
                <v-select dense outlined
                  :items="channelTypes"
                  v-model="advancedSearch.channelId"
                  item-text="description"
                  item-value="id"
                  label="Kanal prenosa" >
                </v-select>
              </v-col>

              <v-col cols="12" class="py-0 my-0">
                <v-select dense outlined
                  :items="outputFormats"
                  v-model="advancedSearch.outputFormatId"
                  item-text="name"
                  item-value="id"
                  label="Izlazni format" >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetAdvancedSearch">
          <v-icon>mdi-restart</v-icon>
          Resetuj pretragu
        </v-btn>
        <v-btn class="primary" @click="performAdvancedSearch">
          Prikaži rezultate
        </v-btn>
      </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import moment from 'moment';
import * as utilsApi from '../api/utils';
import FindCustomerDialog from './FindCustomerDialog';
import FindCompanyDialog from './FindCompanyDialog.vue';
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: "DispatcherRulesAdvancedSearch",
  components: {
    FindCustomerDialog,
    FindCompanyDialog
  },
  data: function () {
    return {
      prioritiesSliderTicksLabels: [ "-", "1", "2", "3", "4", "5" ],
      software: [],
      channelTypes: [],
      outputFormats: [],
      advancedSearch: {
        docType: null,
        softwareID: null,
        sender: {
          name: null
        },
        receiver: {
          name: null
        },
        senderCompany: {
          companyName: null
        },
        receiverCompany: {
          companyName: null
        },
        channelId: null,
        outputFormatId: null,
        priority: null
      }
    }
  },
  mounted() {
    this.getSoftware();
    this.getChannelTypes();
    this.getOutputFormats();
  },
  methods: {
    senderSelected(customer) {
      this.advancedSearch.sender = customer;
    },

    receiverSelected(customer) {
      this.advancedSearch.receiver = customer;
    },

    senderCompanySelected(company) {
      this.advancedSearch.senderCompany = company;
    },

    receiverCompanySelected(company) {
      this.advancedSearch.receiverCompany = company;
    },

    performAdvancedSearch() {
      this.$emit("updateAdvancedSearch", this.advancedSearch);
    },

    resetAdvancedSearch() {
      this.advancedSearch.sender = {
        name: null
      };
      this.advancedSearch.receiver = {
        name: null
      };
      this.advancedSearch.senderCompany = {
        companyName: null
      };
      this.advancedSearch.receiverCompany = {
        companyName: null
      };
      this.advancedSearch.docType = null;
      this.advancedSearch.softwareID = null;
      this.advancedSearch.priority = null;
      this.advancedSearch.outputFormatId = null;
      this.advancedSearch.channelId = null;
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    },

    getSoftware() {
      utilsApi
        .getSoftware(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.software = responseData;
        })
        .catch(() => {});
    },

    getChannelTypes() {
      utilsApi
        .getChannelTypes(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.channelTypes = responseData;
        })
        .catch(() => {});
    },

    getOutputFormats() {
      utilsApi
        .getOutputFormats(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.outputFormats = responseData;
        })
        .catch(() => {});
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    }
  }
}
</script>
