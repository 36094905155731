import * as fetch from './fetch'

export function LogIn(username, password) {
  const requestBody = {
    username: username,
    password: password
  }

  return fetch.POST("/users/login", null, JSON.stringify(requestBody), false)
}

export function postUser(newUserRequest, token) {
  const endpoint = '/users/createUser';
  return fetch.POST(endpoint, token, newUserRequest, true);
}

export function getList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/users'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

function mapToParameters(options) {
  let parameters = {}

  if (options.sortBy && options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.companyId) {
    parameters.companyId = options.companyId;
  }

  if (options.customerId) {
    parameters.customerId = options.customerId;
  }

  return parameters
}
