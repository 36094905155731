var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3 mb-3",attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v(" Alternativni primaoci ")]),_c('v-card-subtitle',[_c('v-switch',{attrs:{"label":"Veze gde je originalni primalac"},on:{"click":_vm.onSwitch},model:{value:(_vm.asOriginalReceiver),callback:function ($$v) {_vm.asOriginalReceiver=$$v},expression:"asOriginalReceiver"}})],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.customersAltReceiversList,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.altCustomerOnly",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.altCustomerOnly === true ? "mdi-check" : "mdi-close"))])]}},{key:"item.documentTypeId",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getDocumentType(item.documentTypeId).letterIcon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDocumentType(item.documentTypeId).name))])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onDeleteAltReceiverLink(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogConfirmDelete),callback:function ($$v) {_vm.dialogConfirmDelete=$$v},expression:"dialogConfirmDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Da li želite da obrišete? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red--text plain ma-2",attrs:{"text":""},on:{"click":function($event){_vm.dialogConfirmDelete = false}}},[_vm._v(" Odustani ")]),_c('v-btn',{staticClass:"error ma-2",attrs:{"text":"","loading":_vm.deleting},on:{"click":_vm.onConfirmDelete}},[_vm._v(" Obriši ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }