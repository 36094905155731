<template>
  <v-card>
    <v-card-title> Dodavanje API opcija </v-card-title>

    <v-card-text class="mt-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="7">
            <v-select
              :items="getDocumentTypes()"
              v-model="newApiOptions.documentType"
              item-text="text"
              item-value="value"
              label="Tip dokumenta *"
              required
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3" v-if="propCustomer === null">
                <find-customer-dialog
                  @update="customerSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto</v-list-item-title>
                      <v-list-item-subtitle>{{getCustomerName()}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="12">
                <v-text-field
                  label="API Url"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.baseUrl"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6">
                <v-text-field
                  label="Korisničko ime"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.username"
                >
                </v-text-field>
              </v-col>

              <v-col sm="6">
                <v-text-field
                  label="Lozinka"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.password"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6">
                <v-switch
                  label="Aktivan"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.active"
                >
                </v-switch>
              </v-col>

              <v-col sm="6">
                <v-switch
                  label="Test"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.isTest"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <small>* označava neophodna polja</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary ma-2 white--text"
        text
        @click="onAddApiOptions"
        :loading="postingToApi"
      >
        Dodaj API opcije
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
  </v-card>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";
import DocumentTypes from "../common/documentTypes.js";

import FindCustomerDialog from "./FindCustomerDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddApiOptions",
  components: {
    Snackbar,
    FindCustomerDialog
  },
  data: function () {
    return {
      postingToApi: false,
      newApiOptions: {},
      selectedCustomer: {},

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  watch: {
    copiedApiOptions: {
      handler() {
        this.newApiOptions = this.copiedApiOptions;
        this.selectedCustomer = this.copiedApiOptions.customer;
      }
    }
  },
  props: {
    propCustomer: {
      type: Object,
      default: null,
      required: false
    },
    copiedApiOptions: {
      type: Object,
      default: null,
      required: false
    }
  },
  methods: {
    onAddApiOptions() {
      this.postingToApi = true;

      this.prepareForPost();

      dispatcherOptionsApi
        .addApiOptions(this.newApiOptions, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodate opcije za slanje na API. ID: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    customerSelected(customer) {
      this.selectedCustomer = customer;
      this.$emit("companySelected", customer.companyId);
    },

    getCustomerName() {
      if (this.propCustomer !== null) {
        return this.propCustomer.name;
      }

      if (this.selectedCustomer !== null) {
        return this.selectedCustomer.name;
      }

      return "";
    },

    getCustomerId() {
      if (this.propCustomer !== null) {
        return this.propCustomer.id;
      }

      if (this.selectedCustomer !== null) {
        return this.selectedCustomer.id;
      }

      return -1;
    },

    prepareForPost() {
      this.newApiOptions.customerId = this.getCustomerId();
    }
  },
};
</script>
