<template>
  <v-dialog v-model="dialogEditFtpClientOptions" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
    </template>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Izmena FTP opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-container>
        <v-row>
          <v-col cols="12" sm="7">
            <v-text-field
              label="Host *"
              v-model="updatedFtpClientOptions.host"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Port *"
              v-model="updatedFtpClientOptions.port"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Folder *"
              v-model="updatedFtpClientOptions.directory"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Username *"
              v-model="updatedFtpClientOptions.username"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Password *"
              v-model="updatedFtpClientOptions.password"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="customerClientSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto klijentske strane"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto klijentske strane</v-list-item-title>
                      <v-list-item-subtitle>{{ updatedFtpClientOptions.customerClient !== null ? updatedFtpClientOptions.customerClient.name : "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-company-dialog
                  @update="companyClientSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi kompaniju klijentske strane"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Kompanija klijentske strane</v-list-item-title>
                      <v-list-item-subtitle>{{ updatedFtpClientOptions.companyClient !== null ? updatedFtpClientOptions.companyClient.companyName : "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="customerServerSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto serverske strane"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto serverske strane</v-list-item-title>
                      <v-list-item-subtitle>{{ updatedFtpClientOptions.customerServer !== null ? updatedFtpClientOptions.customerServer.name : "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red--text plain ma-2" text @click="onCancel">
          Odustani
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
          Obriši
          <v-icon right>mdi-delete</v-icon>
        </v-btn>
        <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
          Sačuvaj izmene
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da sačuvate promene?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
              Odustani
            </v-btn>
            <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
              Sačuvaj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da obrišete?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmDelete = false">
              Odustani
            </v-btn>
            <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
              Obriši
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from '../api/dispatcherOptions.js';
import FindCustomerDialog from "./FindCustomerDialog.vue";
import FindCompanyDialog from "./FindCompanyDialog.vue";

export default {
  name: 'EditFtpClientOptionsDialog',
  components: {
    FindCustomerDialog,
    FindCompanyDialog
  },
  data: function () {
    return {
      dialogEditFtpClientOptions: false,
      dialogConfirmSave: false,
      dialogConfirmDelete: false,
      puttingToApi: false,
      deleting: false,

      updatedFtpClientOptions: {
        customerClient: {
          name: null
        },
        customerServer: {
          name: null
        },
        companyClient: {
          companyName: null
        },
        host: null,
        port: null,
        username: null,
        password: null,
        directory: null
      }
    }
  },
  props: {
    propFtpClientOptions: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.updatedFtpClientOptions = this.shallowCopy(this.propFtpClientOptions);
  },
  methods: {
    shallowCopy(originalObj) {
      return JSON.parse(JSON.stringify(originalObj));
    },
    onCancel() {
      this.updatedFtpClientOptions = this.shallowCopy(this.propFtpClientOptions);
      this.dialogEditFtpClientOptions = false;
    },
    onConfirmEdit() {
      this.puttingToApi = true;

      dispatcherOptionsApi.updateFtpClientOptions(this.propFtpClientOptions.id, this.updatedFtpClientOptions, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditFtpClientOptions = false;

          this.$emit('reloadFtpClientOptions');
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },

    onConfirmDelete() {
      this.deleting = true;

      dispatcherOptionsApi.deleteFtpClientOptions(this.propFtpClientOptions.id, this.$store.getters.token)
        .then(() => {
          return null
        })
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogEditFtpClientOptions = false;

          this.$emit('reloadFtpClientOptions');
        })
        .catch(() => {
          this.deleting = false;
        });
    },
    customerClientSelected(customer) {
      this.updatedFtpClientOptions.clientCID = customer.id;
      this.updatedFtpClientOptions.customerClient = customer;
    },

    companyClientSelected(company) {
      this.updatedFtpClientOptions.clientCoID = company.id;
      this.updatedFtpClientOptions.companyClient = company;
    },

    customerServerSelected(customer) {
      this.updatedFtpClientOptions.serverCID = customer.id;
      this.updatedFtpClientOptions.customerServer = customer;
    },
  }
}
</script>
