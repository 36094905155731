<template>
  <v-card>
    <v-card-title> Dodavanje FTP opcija </v-card-title>

    <v-card-text class="mt-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="7">
            <v-text-field
              label="Host *"
              v-model="newFtpClientOptions.host"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Port *"
              v-model="newFtpClientOptions.port"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Folder *"
              v-model="newFtpClientOptions.directory"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Username *"
              v-model="newFtpClientOptions.username"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="7">
            <v-text-field
              label="Password *"
              v-model="newFtpClientOptions.password"
              dense outlined hide-details
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="customerClientSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto klijentske strane"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto klijentske strane</v-list-item-title>
                      <v-list-item-subtitle>{{ newFtpClientOptions.customerClient !== null ? newFtpClientOptions.customerClient.name : "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-company-dialog
                  @update="companyClientSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi kompaniju klijentske strane"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Kompanija klijentske strane</v-list-item-title>
                      <v-list-item-subtitle>{{ newFtpClientOptions.companyClient !== null ? newFtpClientOptions.companyClient.companyName : "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="customerServerSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto serverske strane"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto serverske strane</v-list-item-title>
                      <v-list-item-subtitle>{{ newFtpClientOptions.customerServer !== null ? newFtpClientOptions.customerServer.name : "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <small>* označava neophodna polja</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary ma-2 white--text"
        text
        @click="onAddFtpOptions"
        :loading="postingToApi"
      >
        Dodaj FTP opcije
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
  </v-card>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";

import FindCustomerDialog from "./FindCustomerDialog.vue";
import FindCompanyDialog from "./FindCompanyDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddFtpClientOptions",
  components: {
    Snackbar,
    FindCustomerDialog,
    FindCompanyDialog
  },
  data: function () {
    return {
      postingToApi: false,
      newFtpClientOptions: {
        clientCoID: null,
        clientCID: null,
        serverCID: null,
        customerClient: null,
        companyClient: null,
        customerServer: null
      },
      selectedCustomer: {},

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  watch: {
    copiedFtpClientOptions: {
      handler() {
        this.newFtpClientOptions = this.copiedFtpClientOptions;
        this.selectedCustomer = this.copiedFtpClientOptions.customer;
      }
    }
  },
  props: {
    copiedFtpClientOptions: {
      type: Object,
      default: null,
      required: false
    }
  },
  methods: {
    onAddFtpOptions() {
      this.postingToApi = true;

      dispatcherOptionsApi
        .addFtpOptions(this.newFtpClientOptions, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodate opcije za slanje na FTP. ID: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    customerClientSelected(customer) {
      this.newFtpClientOptions.clientCID = customer.id;
      this.newFtpClientOptions.customerClient = customer;
      this.$emit("companySelected", customer.companyId);
    },

    companyClientSelected(company) {
      this.newFtpClientOptions.clientCoID = company.id;
      this.newFtpClientOptions.companyClient = company;
      this.$emit("companySelected", company.id);
    },

    customerServerSelected(customer) {
      this.newFtpClientOptions.serverCID = customer.id;
      this.newFtpClientOptions.customerServer = customer;
      this.$emit("companySelected", customer.companyId);
    },
  },
};
</script>
