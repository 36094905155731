<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col sm="6">
        <add-alt-receivers-for-company
          :company="selectedCompany"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCompanies from "../api/companies.js";

import AddAltReceiversForCompany from '../components/AddAltReceiversForCompany.vue';

export default {
  name: "AddAltReceiversForCompanyPage",
  components: {
    AddAltReceiversForCompany,
  },
  data() {
    return {
      selectedCompany: null,
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getCompany(id);
    }
  },
  methods: {
    getCompany(id) {
      this.loading = true;

      apiCompanies.getCompany(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.selectedCompany = responseData;
          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.selectedCompany = null;
          this.loading = false;
        })
    },
  }
}
</script>
