import * as fetch from './fetch'

export function getList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/portalusers'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function postUser(newUserRequest, token) {
  const endpoint = '/portalusers/createUser';
  return fetch.POST(endpoint, token, newUserRequest, true);
}

function mapToParameters(options) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.customerId) {
    parameters.customerId = options.customerId;
  }

  return parameters
}
