import * as fetch from './fetch'

export function getList(options, advancedSearchOptions, token) {
  let parameters = mapToParameters(options, advancedSearchOptions);

  const endpoint = '/dispatcher';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function getByID(id, token) {
  const endpoint = '/dispatcher/' + id;
  return fetch.GET(endpoint, token, true);
}

export function deleteDispatcherRule(id, token) {
  const endpoint = '/dispatcher/' + id;
  return fetch.DELETE(endpoint, token, true);
}

export function addRule(newRule, token) {
  const endpoint = '/dispatcher/rule';
  return fetch.POST(endpoint, token, newRule, true);
}

export function addException(newException, token) {
  const endpoint = '/dispatcher/exception';
  return fetch.POST(endpoint, token, newException, true);
}

function mapToParameters(options, advancedSearchOptions) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (advancedSearchOptions.sender && advancedSearchOptions.sender.id) {
    parameters.senderId = advancedSearchOptions.sender.id;
  }

  if (advancedSearchOptions.receiver && advancedSearchOptions.receiver.id) {
    parameters.receiverId = advancedSearchOptions.receiver.id;
  }

  if (advancedSearchOptions.senderCompany && advancedSearchOptions.senderCompany.id) {
    parameters.senderCompanyId = advancedSearchOptions.senderCompany.id;
  }

  if (advancedSearchOptions.receiverCompany && advancedSearchOptions.receiverCompany.id) {
    parameters.receiverCompanyId = advancedSearchOptions.receiverCompany.id;
  }

  if (advancedSearchOptions.priority) {
    parameters.priority = advancedSearchOptions.priority;
  }

  if (advancedSearchOptions.channelId) {
    parameters.channelId = advancedSearchOptions.channelId;
  }

  if (advancedSearchOptions.outputFormatId) {
    parameters.outputFormatId = advancedSearchOptions.outputFormatId;
  }

  if (advancedSearchOptions.docType) {
    parameters.documentTypeId = advancedSearchOptions.docType;
  }

  return parameters
}
