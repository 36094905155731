import * as fetch from './fetch'


export function putCustomer(id, updatedCustomer, token) {
  const endpoint = '/customers/' + id;
  return fetch.PUT(endpoint, token, updatedCustomer, true);
}


export function postCustomer(newCustomerObject, token) {
  const endpoint = '/customers'
  return fetch.POST(endpoint, token, newCustomerObject)
}

export function getCustomer(id, token) {
  const endpoint = '/customers/' + id;
  return fetch.GET(endpoint, token, true);
}

export function getCustomersOfCompany(companyId, token) {
  const endpoint = '/customers/byCompany/' + companyId;
  return fetch.GET(endpoint, token, true);
}

export function getList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/customers'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function getAlternativeReceiverSuggestions(companyId, documentType, token) {
  let endpoint = '/customers/altReceiverSuggestions/' + companyId;
  if (documentType) {
    endpoint += '?documentType=' + documentType
  }

  return fetch.GET(endpoint, token, true);
}

export function getAlternativeReceiversOfCustomer(customerId, asOriginalReceiver, pageNum, pageSize, token) {
  let endpoint = '/customers/altReceivers/' + customerId;

  let parameters = {
    asOriginalReceiver: asOriginalReceiver,
    pageNum: pageNum,
    pageSize: pageSize
  };

  let queryString = new URLSearchParams(parameters).toString()

  endpoint += '?' + queryString;
  return fetch.GET(endpoint, token, true);
}

export function deleteAltReceiverLink(linkId, token) {
  const endpoint = '/customers/link/' + linkId;
  return fetch.DELETE(endpoint, token, true);
}

export function addNewAlternativeReceiverLink(newLink, token) {
  const endpoint = '/customers/link';
  return fetch.POST(endpoint, token, newLink, true);
}

export function addNewAltReceiversForCompany(altReceiversForCompany, token) {
  const endpoint = '/customers/companyLinks';
  return fetch.POST(endpoint, token, altReceiversForCompany, true);
}

function mapToParameters(options) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.companyId) {
    parameters.companyId = options.companyId
  }

  if (options.isCodeSearch !== null) {
    parameters.searchCodes = options.isCodeSearch;
  }

  if (options.isSearchByID !== null) {
    parameters.searchById = options.isSearchByID;
  }

  if (options.customerType !== null) {
    parameters.customerType = options.customerType;
  }

  return parameters
}
