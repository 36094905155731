<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="5">
        <!-- <img width="400px" src="../assets/altermedia-logo.png"> -->
        <v-img :src="altermediaLogo" max-height="150">
        </v-img>
        <div class="text-center">
          Copyright &copy; 2021
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="text-center headline">
        TEST
      </div>
    </v-row>
    <v-row align="bottom">
      <v-col>
        <div class="text-center">
          Poslednja izmena: 05.08.2021.
        </div>

        <div class="text-center">
          V1.6.1-dev
        </div>
      </v-col>

    </v-row>
</v-container>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      altermediaLogo: require('../assets/altermedia-logo.png')
    }
  }
}
</script>
