<template>
  <v-dialog v-model="dialogNewEmailOptionsForCompany" @keydown.esc="onCancel" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" dark v-bind="attrs" v-on="on">
        Dodaj Email opcije za kompaniju
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title> Dodavanje Email opcija za celu kompaniju</v-card-title>

      <v-card-text class="mt-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="7">
              <v-select
                :items="getDocumentTypes()"
                v-model="newEmailOptions.documentType"
                item-text="text"
                item-value="value"
                label="Tip dokumenta *"
                required
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="2" class="mt-3">
                  <find-company-dialog
                    @update="companySelected"
                    :isSmall="true"
                    icon="mdi-magnify"
                    buttonText=""
                    toolbarText="Izaberi kompaniju"
                  />
                </v-col>
                <v-col sm="10">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Kompanija</v-list-item-title>
                        <v-list-item-subtitle>{{this.selectedCompany.companyName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="6">
                  <v-text-field
                    label="To *"
                    outlined
                    dense
                    hide-details
                    v-model="textBoxEmailReceiver"
                    append-icon="mdi-plus"
                    @click:append="addEmailReceiver"
                  >
                  </v-text-field>

                  <v-chip
                    v-for="(emailRec , index) in emailReceivers"
                    :key="index"
                    class="ma-1"
                    close
                    @click:close="removeEmailReceiver(index)"
                  >
                    {{emailRec}}
                  </v-chip>
                </v-col>

                <v-col sm="6">
                  <v-text-field
                    label="CC"
                    outlined
                    dense
                    hide-details
                    v-model="textBoxEmailCC"
                    append-icon="mdi-plus"
                    @click:append="addEmailCC"
                  >
                  </v-text-field>

                  <v-chip
                    v-for="(emailCC, index) in emailCCs"
                    :key="index"
                    class="ma-1"
                    close
                    @click:close="removeEmailCC(index)"
                  >
                    {{emailCC}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                v-model="newEmailOptions.subject"
                label="Subject *"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-textarea
                label="Body *"
                outlined
                hide-details
                auto-grow
                v-model="newEmailOptions.body">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-btn class="error ma-2 white--text" text @click="onCancel">
          Odustani
          <v-icon right>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          class="primary ma-2 white--text"
          text
          @click="onAddEmailOptions"
          :loading="postingToApi"
        >
          Dodaj Email opcije
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>

      <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";
import DocumentTypes from "../common/documentTypes.js";

import FindCompanyDialog from "./FindCompanyDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddEmailOptionsForCompany",
  components: {
    Snackbar,
    FindCompanyDialog
  },
  data: function () {
    return {
      dialogNewEmailOptionsForCompany: false,
      postingToApi: false,
      newEmailOptions: {},
      selectedCompany: {
        companyName: null
      },

      textBoxEmailReceiver: null,
      textBoxEmailCC: null,
      emailReceivers: [],
      emailCCs: [],

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  methods: {
    onAddEmailOptions() {
      this.postingToApi = true;

      this.prepareForPost();

      dispatcherOptionsApi
        .addEmailOptionsForCompany(this.newEmailOptions, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodato " + responseData.length + " opcija za slanje na Email za kompaniju ID: " + this.selectedCompany.id);

          // cekaj 5s pa zatvori modalni prozor
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;

          // cekaj 5s pa zatvori modalni prozor
          setTimeout(function () {
            // zatvaranje modalnog prozora
            this.dialogNewCompany = false;
          }, 5000);
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    companySelected(company) {
      this.selectedCompany = company;
      this.$emit("companySelected", company.id);
    },

    addEmailReceiver() {
      if (this.textBoxEmailReceiver !== null &&
          this.textBoxEmailReceiver.length > 0) {
        this.emailReceivers.push(this.textBoxEmailReceiver);
        this.textBoxEmailReceiver = null;
      }
    },

    removeEmailReceiver(index) {
      if (index < 0) {
        return;
      }

      this.emailReceivers.splice(index, 1);
    },

    addEmailCC() {
      if (this.textBoxEmailCC !== null &&
          this.textBoxEmailCC.length > 0) {
        this.emailCCs.push(this.textBoxEmailCC);
        this.textBoxEmailCC = null;
      }
    },

    removeEmailCC(index) {
      if (index < 0) {
        return;
      }

      this.emailCCs.splice(index, 1);
    },

    prepareForPost() {
      this.newEmailOptions.companyId = this.selectedCompany.id;
      this.newEmailOptions.emailReceivers = this.emailReceivers;
      this.newEmailOptions.ccs = this.emailCCs;
    },

    onCancel () {
      this.dialogNewEmailOptionsForCompany = false;
      this.newEmailOptions = {};
    },
  },
};
</script>
