<template>
  <v-card class="mt-3 mb-3" width="100%">
    <v-card-title>
      Sugestije za alternativnog primaoca
    </v-card-title>
    <v-card-subtitle>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="altReceiverSuggestionsList"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="copyAltReceiver(item)">mdi-content-copy</v-icon>
      </template>

      <template v-slot:[`item.documentTypeId`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ getDocumentType(item.documentTypeId).letterIcon }}</v-icon>
          </template>
          <span>{{getDocumentType(item.documentTypeId).name}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as customersAPI from '../api/customers.js';
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: "AltReceiverSuggestions",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      altReceiverSuggestionsList: [],
      headers: [
          { text: 'ID', value: 'id', width: "10%" },
          { text: 'Naziv', value: 'name' },
          { text: 'Adresa', value: 'address', width: "30%" },
          { text: 'Akcije', value: 'actions', width: "15%" }
      ],
    }
  },
  props: {
    companyId: {
      type: Number,
      required: false
    },
    documentType: {
      type: Number,
      required: false
    }
  },
  watch: {
    companyId: {
      handler() {
        this.getAltReceiversFromAPI();
      }
    },
    documentType: {
      handler() {
        this.getAltReceiversFromAPI();
      }
    },
    options: {
      handler () {
        //this.getAltReceiversFromAPI();
      },
      deep: true,
    }
  },
  mounted () {
    this.getAltReceiversFromAPI();
  },
  methods: {
    getAltReceiversFromAPI() {
      if (this.companyId === null || this.companyId === 0) {
        return;
      }

      this.loading = true;

      customersAPI.getAlternativeReceiverSuggestions(this.companyId, this.documentType, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.altReceiverSuggestionsList = responseData
        })
        .catch(() => {
          this.altReceiverSuggestionsList = []
          this.loading = false
        })
    },
    getDocumentType(documentTypeId) {
      return DocumentTypes.getById(documentTypeId);
    },
    copyAltReceiver(customer) {
      this.$emit('altReceiverSelected', customer);
    }
  }
}
</script>
