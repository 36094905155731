<template>
  <v-dialog v-model="dialogFileNameFormat" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
    </template>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Izmena šablona za nazivanje fajlova opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-row>
          <v-col cols="12" sm="7">
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Šablon (ID: {{ this.propFileNameFormat.fileNameFormat.id }})</v-list-item-title>
                  <v-list-item-subtitle>{{ this.propFileNameFormat.fileNameFormat.template }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Isporučno mesto</v-list-item-title>
                  <v-list-item-subtitle>{{ this.propFileNameFormat.customer.name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Tip dokumenta</v-list-item-title>
                  <v-list-item-subtitle>{{ getDocumentTypeName() }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Datum i vreme kreiranja veze</v-list-item-title>
                  <v-list-item-subtitle>{{ format_datetime(this.propFileNameFormat.createdAt) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red--text plain ma-2" text @click="onCancel">
          Odustani
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
          Obriši
          <v-icon right>mdi-delete</v-icon>
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da obrišete?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmDelete = false">
              Odustani
            </v-btn>
            <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
              Obriši
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from '../api/dispatcherOptions.js';
import moment from 'moment';
import DocumentTypes from "../common/documentTypes.js";

export default {
  name: 'EditFileNameFormatDialog',
  data: function () {
    return {
      dialogFileNameFormat: false,
      dialogConfirmDelete: false,
      deleting: false
    }
  },
  props: {
    propFileNameFormat: {
      type: Object,
      required: true
    }
  },
  mounted () {
  },
  methods: {
    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },

    onCancel() {
      this.dialogFileNameFormat = false;
    },

    onConfirmDelete() {
      this.deleting = true;

      dispatcherOptionsApi.deleteCustomerFileNameFormat(this.propFileNameFormat.id, this.$store.getters.token)
        .then(() => {
          return null
        })
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogFileNameFormat = false;

          this.$emit('reloadFileNameFormats');
        })
        .catch(() => {
          this.deleting = false;
        });
    },

    getDocumentTypeName() {
      return DocumentTypes.getById(this.propFileNameFormat.documentType).name;
    }
  }
}
</script>
