<template>
  <v-container class="mt-14">
    <v-row aalign="center" justify="center">
      <v-col sm="6">
        <add-customer-file-name-format
          :copiedCustomerFileNameFormat="customerFileNameFormat"
          :propCustomer="customer"
          @companySelected="onCompanySelected"/>
      </v-col>

      <v-col sm="8">
        <customer-file-name-formats-list
          :companyId="selectedCompanyId"
          @customerFileNameFormatCopied="passCopiedCustomerFileNameFormat"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCustomers from "../api/customers.js";

import AddCustomerFileNameFormat from "../components/AddCustomerFileNameFormat.vue";
import CustomerFileNameFormatsList from "../components/CustomerFileNameFormatsList.vue";

export default {
  name: "AddCustomerFileNameFormatPage",
  components: {
    AddCustomerFileNameFormat,
    CustomerFileNameFormatsList
  },
  data() {
    return {
      selectedCompanyId: null,
      customerFileNameFormat: null,
      customer: null
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getCustomer(id);
    }
  },
  methods: {
    onCompanySelected(compId) {
      this.selectedCompanyId = compId;
    },

    passCopiedCustomerFileNameFormat(customerFileNameFormat) {
      this.customerFileNameFormat = customerFileNameFormat;
    },

    getCustomer(id) {
      this.loading = true;

      apiCustomers.getCustomer(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customer = responseData;
          this.selectedCompanyId = this.customer.companyId;
          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.customer = null;
          this.loading = false;
        })
    },
  }
}
</script>
