<template>
  <v-dialog v-model="dialogEditEmailOptions" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
    </template>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Izmena Email opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="7">
              <v-select
                :items="getDocumentTypes()"
                v-model="updatedEmailOptions.documentType"
                item-text="text"
                item-value="value"
                label="Tip dokumenta *"
                required
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Isporučno mesto</v-list-item-title>
                    <v-list-item-subtitle>{{ this.updatedEmailOptions.customer.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="6">
                  <v-text-field
                    label="To *"
                    outlined
                    dense
                    hide-details
                    v-model="textBoxEmailReceiver"
                    append-icon="mdi-plus"
                    @click:append="addEmailReceiver"
                  >
                  </v-text-field>

                  <v-chip
                    v-for="(emailRec , index) in updatedEmailOptions.emailReceivers"
                    :key="index"
                    class="ma-1"
                    close
                    @click:close="removeEmailReceiver(index)"
                  >
                    {{emailRec}}
                  </v-chip>
                </v-col>

                <v-col sm="6">
                  <v-text-field
                    label="CC"
                    outlined
                    dense
                    hide-details
                    v-model="textBoxEmailCC"
                    append-icon="mdi-plus"
                    @click:append="addEmailCC"
                  >
                  </v-text-field>

                  <v-chip
                    v-for="(emailCC, index) in updatedEmailOptions.ccs"
                    :key="index"
                    class="ma-1"
                    close
                    @click:close="removeEmailCC(index)"
                  >
                    {{emailCC}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                v-model="updatedEmailOptions.subject"
                label="Subject *"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-textarea
                label="Body *"
                outlined
                hide-details
                auto-grow
                v-model="updatedEmailOptions.body">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red--text plain ma-2" text @click="onCancel">
          Odustani
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
          Obriši
          <v-icon right>mdi-delete</v-icon>
        </v-btn>
        <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
          Sačuvaj izmene
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da sačuvate promene?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
              Odustani
            </v-btn>
            <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
              Sačuvaj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
        <v-card>
          <v-card-title>
            Da li želite da obrišete?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text plain ma-2" text @click="dialogConfirmDelete = false">
              Odustani
            </v-btn>
            <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
              Obriši
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from '../api/dispatcherOptions.js';
import DocumentTypes from '../common/documentTypes.js';

export default {
  name: 'EditEmailOptionsDialog',
  data: function () {
    return {
      dialogEditEmailOptions: false,
      dialogConfirmSave: false,
      dialogConfirmDelete: false,
      puttingToApi: false,
      deleting: false,

      updatedEmailOptions: {
        customer: {
          name: null
        },
        documentType: null,
        subject: null,
        body: null,
        emailReceivers: [],
        ccs: []
      },

      textBoxEmailReceiver: null,
      textBoxEmailCC: null,
    }
  },
  props: {
    propEmailOptions: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.updatedEmailOptions = this.shallowCopy(this.propEmailOptions);
  },
  methods: {
    shallowCopy(originalObj) {
      return JSON.parse(JSON.stringify(originalObj));
    },
    onCancel() {
      this.updatedEmailOptions = this.shallowCopy(this.propEmailOptions);
      this.textBoxEmailReceiver = null;
      this.textBoxEmailCC = null;
      this.dialogEditEmailOptions = false;
    },
    onConfirmEdit() {
      this.puttingToApi = true;

      dispatcherOptionsApi.updateEmailOptions(this.propEmailOptions.id, this.updatedEmailOptions, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditEmailOptions = false;

          // brisanje unetih podataka
          this.updatedEmailOptions = {};

          this.$emit('reloadEmailOptions');
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },

    onConfirmDelete() {
      this.deleting = true;

      dispatcherOptionsApi.deleteEmailOptions(this.propEmailOptions.id, this.$store.getters.token)
        .then(() => {
          return null
        })
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogEditEmailOptions = false;

          this.$emit('reloadEmailOptions');
        })
        .catch(() => {
          this.deleting = false;
        });
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    addEmailReceiver() {
      if (this.textBoxEmailReceiver !== null &&
          this.textBoxEmailReceiver.length > 0) {
        this.updatedEmailOptions.emailReceivers.push(this.textBoxEmailReceiver);
        this.textBoxEmailReceiver = null;
      }
    },

    removeEmailReceiver(index) {
      if (index < 0) {
        return;
      }

      this.updatedEmailOptions.emailReceivers.splice(index, 1);
    },

    addEmailCC() {
      if (this.textBoxEmailCC !== null &&
          this.textBoxEmailCC.length > 0) {
        this.updatedEmailOptions.ccs.push(this.textBoxEmailCC);
        this.textBoxEmailCC = null;
      }
    },

    removeEmailCC(index) {
      if (index < 0) {
        return;
      }

      this.updatedEmailOptions.ccs.splice(index, 1);
    },
  }
}
</script>
