<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-col cols="12" sm="12" md="6">
              <v-btn class="primary ma-2 " dark to="/dispatcher/create-rule">
                Dodaj pravilo
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn class="primary ma-2 " dark to="/dispatcher/create-exception">
                Dodaj izuzetak
                <v-icon class="ml-2">mdi-plus</v-icon>
              </v-btn>

              <v-spacer></v-spacer>
            </v-col>
            <v-spacer />
          </v-row>

          <dispatcher-rules-advanced-search @updateAdvancedSearch="onChangeAdvancedSearch" />
          <dispatcher-rules-list :advancedSearch="advancedSearch" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import DispatcherRulesList from "../components/DispatcherRulesList.vue";
import DispatcherRulesAdvancedSearch from "../components/DispatcherRulesAdvancedSearch.vue";
export default {
  name: "DispatcherPage",
  components: {
    DispatcherRulesList,
    DispatcherRulesAdvancedSearch
  },
  data () {
    return {
      advancedSearch: {
        docType: null,
        softwareID: null,
        sender: {
          name: null
        },
        receiver: {
          name: null
        },
        senderCompany: {
          companyName: null
        },
        receiverCompany: {
          companyName: null
        }
      }
    }
  },
   methods: {
    onChangeAdvancedSearch(newAdvancedSearch) {
      this.advancedSearch = newAdvancedSearch;
    }
  }
};
</script>
