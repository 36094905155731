<template>
  <v-dialog v-model="dialogDetailsApiOptions" @keydown.esc="onCancel" persistent max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
    </template>
    <v-card v-if="propApiOptions !== null">
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Detalji API opcija
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-0">
        <v-row>
          <v-col>
            <v-list dense>
              <v-list-item v-for="item in apiOptionsProperties" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getApiOptionsProperty(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import DocumentTypes from "../common/documentTypes.js";

export default {
  name: 'ApiOptionsDetailsDialog',
  data: function () {
    return {
      dialogDetailsApiOptions: false,
      apiOptionsProperties: [
        { label: "Tip dokumenta", propertyName: 'documentType', icon: "mdi-file-document-outline" },
        { label: "Isporučno mesto", propertyName: 'customer.name', icon: "mdi-map-marker" },
        { label: "API Url", propertyName: 'baseUrl', icon: "mdi-web" },
        { label: "Korisničko ime", propertyName: 'username', icon: "mdi-account" },
        { label: "Lozinka", propertyName: 'password', icon: "mdi-lock" },
        { label: "Aktivan", propertyName: 'active', icon: "mdi-check-circle" },
        { label: "Test", propertyName: 'isTest', icon: "mdi-test-tube" },
        { label: "Datum kreiranja", propertyName: 'createdAt', icon: "mdi-sort-calendar-ascending" },
        { label: "Datum ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ]
    }
  },
  props: {
    propApiOptions: {
      type: Object,
      required: true
    }
  },
  methods: {
    onCancel() {
      this.dialogDetailsApiOptions = false;
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    getApiOptionsProperty(propertyName) {
      if (propertyName === "documentType") {
        return DocumentTypes.getById(this.propApiOptions.documentType).name;
      }

      let dateProperties = ["createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDate(this.propApiOptions[propertyName]);
      }

      if (propertyName === "active" || propertyName === "isTest") {
        let boolValue = this.propApiOptions[propertyName];
        if (boolValue) {
          return "Da";
        }
        else {
          return "Ne";
        }
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let customer = this.propApiOptions[firstPart];
        return customer[secondPart];
      }

      return this.propApiOptions[propertyName];
    }
  }
}
</script>
