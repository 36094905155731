<template>
  <v-dialog v-model="dialogNewApiOptionsForCompany" @keydown.esc="onCancel" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" dark v-bind="attrs" v-on="on">
        Dodaj API opcije za kompaniju
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title> Dodavanje API opcija za celu kompaniju</v-card-title>

      <v-card-text class="mt-0">
        <v-container>
          <v-row>
            <v-col cols="12" sm="7">
              <v-select
                :items="getDocumentTypes()"
                v-model="newApiOptions.documentType"
                item-text="text"
                item-value="value"
                label="Tip dokumenta *"
                required
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="2" class="mt-3">
                  <find-company-dialog
                    @update="companySelected"
                    :isSmall="true"
                    icon="mdi-magnify"
                    buttonText=""
                    toolbarText="Izaberi kompaniju"
                  />
                </v-col>
                <v-col sm="10">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Kompanija</v-list-item-title>
                        <v-list-item-subtitle>{{this.selectedCompany.companyName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col sm="12">
                  <v-text-field
                    label="API Url"
                    outlined
                    dense
                    hide-details
                    v-model="newApiOptions.baseUrl"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
              <v-col sm="6">
                <v-text-field
                  label="Korisničko ime"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.username"
                >
                </v-text-field>
              </v-col>

              <v-col sm="6">
                <v-text-field
                  label="Lozinka"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.password"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6">
                <v-switch
                  label="Aktivan"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.active"
                >
                </v-switch>
              </v-col>

              <v-col sm="6">
                <v-switch
                  label="Test"
                  outlined
                  dense
                  hide-details
                  v-model="newApiOptions.isTest"
                >
                </v-switch>
              </v-col>
            </v-row>
            </v-col>
          </v-row>
        </v-container>
        <small>* označava neophodna polja</small>
      </v-card-text>
      <v-card-actions>
        <v-btn class="error ma-2 white--text" text @click="onCancel">
          Odustani
          <v-icon right>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          class="primary ma-2 white--text"
          text
          @click="onAddApiOptions"
          :loading="postingToApi"
        >
          Dodaj API opcije
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>

      <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
    </v-card>
  </v-dialog>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";
import DocumentTypes from "../common/documentTypes.js";

import FindCompanyDialog from "./FindCompanyDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddApiOptionsForCompany",
  components: {
    Snackbar,
    FindCompanyDialog
  },
  data: function () {
    return {
      dialogNewApiOptionsForCompany: false,
      postingToApi: false,
      newApiOptions: {},
      selectedCompany: {
        companyName: null
      },

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  methods: {
    onAddApiOptions() {
      this.postingToApi = true;

      this.prepareForPost();

      dispatcherOptionsApi
        .addApiOptionsForCompany(this.newApiOptions, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodato " + responseData.length + " opcija za slanje na API za kompaniju ID: " + this.selectedCompany.id);

          // cekaj 5s pa zatvori modalni prozor
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;

          // cekaj 5s pa zatvori modalni prozor
          setTimeout(function () {
            // zatvaranje modalnog prozora
            this.dialogNewCompany = false;
          }, 5000);
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    companySelected(company) {
      this.selectedCompany = company;
      this.$emit("companySelected", company.id);
    },

    prepareForPost() {
      this.newApiOptions.companyId = this.selectedCompany.id;
    },

    onCancel () {
      this.dialogNewApiOptionsForCompany = false;
      this.newApiOptions = {};
    },
  },
};
</script>
