<template>
  <v-card>
    <v-card-title> Dodavanje Email opcija </v-card-title>

    <v-card-text class="mt-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="7">
            <v-select
              :items="getDocumentTypes()"
              v-model="newEmailOptions.documentType"
              item-text="text"
              item-value="value"
              label="Tip dokumenta *"
              required
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3" v-if="propCustomer === null">
                <find-customer-dialog
                  @update="customerSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto</v-list-item-title>
                      <v-list-item-subtitle>{{getCustomerName()}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="6">
                <v-text-field
                  label="To *"
                  outlined
                  dense
                  hide-details
                  v-model="textBoxEmailReceiver"
                  append-icon="mdi-plus"
                  @click:append="addEmailReceiver"
                >
                </v-text-field>

                <v-chip
                  v-for="(emailRec , index) in emailReceivers"
                  :key="index"
                  class="ma-1"
                  close
                  @click:close="removeEmailReceiver(index)"
                >
                  {{emailRec}}
                </v-chip>
              </v-col>

              <v-col sm="6">
                <v-text-field
                  label="CC"
                  outlined
                  dense
                  hide-details
                  v-model="textBoxEmailCC"
                  append-icon="mdi-plus"
                  @click:append="addEmailCC"
                >
                </v-text-field>

                <v-chip
                  v-for="(emailCC, index) in emailCCs"
                  :key="index"
                  class="ma-1"
                  close
                  @click:close="removeEmailCC(index)"
                >
                  {{emailCC}}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              v-model="newEmailOptions.subject"
              label="Subject *"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-textarea
              label="Body *"
              outlined
              hide-details
              auto-grow
              v-model="newEmailOptions.body">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <small>* označava neophodna polja</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary ma-2 white--text"
        text
        @click="onAddEmailOptions"
        :loading="postingToApi"
      >
        Dodaj Email opcije
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
  </v-card>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";
import DocumentTypes from "../common/documentTypes.js";

import FindCustomerDialog from "../components/FindCustomerDialog.vue";
import Snackbar from "../components/Snackbar.vue";

export default {
  name: "AddEmailOptions",
  components: {
    Snackbar,
    FindCustomerDialog
  },
  data: function () {
    return {
      postingToApi: false,
      newEmailOptions: {},

      textBoxEmailReceiver: null,
      textBoxEmailCC: null,

      emailReceivers: [],
      emailCCs: [],

      selectedCustomer: {},

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  watch: {
    copiedEmailOptions: {
      handler() {
        this.newEmailOptions = this.copiedEmailOptions;
        this.selectedCustomer = this.copiedEmailOptions.customer;
        this.emailReceivers = this.copiedEmailOptions.emailReceivers;
        this.emailCCs = this.copiedEmailOptions.ccs;
      }
    }
  },
  props: {
    propCustomer: {
      type: Object,
      default: null,
      required: false
    },
    copiedEmailOptions: {
      type: Object,
      default: null,
      required: false
    }
  },
  methods: {
    onAddEmailOptions() {
      this.postingToApi = true;

      this.prepareForPost();

      dispatcherOptionsApi
        .addEmailOptions(this.newEmailOptions, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodate opcije za slanje na Email. ID: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    customerSelected(customer) {
      this.selectedCustomer = customer;
      this.$emit("companySelected", customer.companyId);
    },

    getCustomerName() {
      if (this.propCustomer !== null) {
        return this.propCustomer.name;
      }

      if (this.selectedCustomer !== null) {
        return this.selectedCustomer.name;
      }

      return "";
    },

    getCustomerId() {
      if (this.propCustomer !== null) {
        return this.propCustomer.id;
      }

      if (this.selectedCustomer !== null) {
        return this.selectedCustomer.id;
      }

      return -1;
    },

    addEmailReceiver() {
      if (this.textBoxEmailReceiver !== null &&
          this.textBoxEmailReceiver.length > 0) {
        this.emailReceivers.push(this.textBoxEmailReceiver);
        this.textBoxEmailReceiver = null;
      }
    },

    removeEmailReceiver(index) {
      if (index < 0) {
        return;
      }

      this.emailReceivers.splice(index, 1);
    },

    addEmailCC() {
      if (this.textBoxEmailCC !== null &&
          this.textBoxEmailCC.length > 0) {
        this.emailCCs.push(this.textBoxEmailCC);
        this.textBoxEmailCC = null;
      }
    },

    removeEmailCC(index) {
      if (index < 0) {
        return;
      }

      this.emailCCs.splice(index, 1);
    },

    prepareForPost() {
      this.newEmailOptions.customerId = this.getCustomerId();
      this.newEmailOptions.emailReceivers = this.emailReceivers;
      this.newEmailOptions.ccs = this.emailCCs;
    }
  },
};
</script>
