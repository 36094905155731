<template>
  <v-container class="mt-8" fluid>
    <v-row align="center" justify="center">
      <v-col sm="10">
          <v-btn class="primary mr-2" to="/sendoptions/email/create">
            Dodaj Email opcije
            <v-icon right>mdi-plus</v-icon>
          </v-btn>

           <add-email-options-for-company />
      </v-col>
      <v-col sm="11">
        <email-options-list
          :showCopyButton="false"
          class="mt-3"
        />
      </v-col>

      <v-col sm="8">
        <v-btn class="primary mr-2" to="/sendoptions/api/create">
          Dodaj API opcije
          <v-icon right>mdi-plus</v-icon>
        </v-btn>

        <add-api-options-for-company />
      </v-col>
      <v-col sm="8">
        <api-options-list
          :showCopyButton="false"
          class="mt-3"
        />
      </v-col>

      <v-col sm="8">
        <add-file-name-format-dialog />

        <v-btn class="ml-2 mr-2 primary" to="/sendoptions/customerFileNameFormat/create">
          Poveži isporučno mesto i šablon
          <v-icon right>mdi-plus</v-icon>
        </v-btn>

        <add-company-file-name-format />
      </v-col>
      <v-col sm="8">
        <customer-file-name-formats-list
          :showCopyButton="false"
          class="mt-3"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmailOptionsList from '../components/EmailOptionsList.vue';
import ApiOptionsList from '../components/ApiOptionsList.vue';
import CustomerFileNameFormatsList from '../components/CustomerFileNameFormatsList.vue';
import AddFileNameFormatDialog from '../components/AddFileNameFormatDialog.vue';
import AddApiOptionsForCompany from '../components/AddApiOptionsForCompany.vue';
import AddEmailOptionsForCompany from '../components/AddEmailOptionsForCompany.vue';
import AddCompanyFileNameFormat from '../components/AddCompanyFileNameFormat.vue';

export default ({
  name: "SendOptionsPage",
  components: {
    EmailOptionsList,
    ApiOptionsList,
    CustomerFileNameFormatsList,
    AddFileNameFormatDialog,
    AddApiOptionsForCompany,
    AddEmailOptionsForCompany,
    AddCompanyFileNameFormat
  }
})
</script>
