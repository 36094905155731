var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Pravila i izuzeci "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfDispatcherRules,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.updatedAt)))])]}},{key:"item.receiverCompany",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCompanyInfo(item.receiverCompany)))])]}},{key:"item.senderCompany",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCompanyInfo(item.senderCompany)))])]}},{key:"item.receiver",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCustomerInfo(item.receiver)))])]}},{key:"item.sender",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCustomerInfo(item.sender)))])]}},{key:"item.documentTypeID",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getDocumentType(item.documentTypeID).letterIcon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDocumentType(item.documentTypeID).name))])])]}}],null,true)}),_c('snackbar',{attrs:{"isError":_vm.snackbar.isError,"text":_vm.snackbar.text,"showSnackbar":_vm.snackbar.show},on:{"update:showSnackbar":function($event){return _vm.$set(_vm.snackbar, "show", $event)},"update:show-snackbar":function($event){return _vm.$set(_vm.snackbar, "show", $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }