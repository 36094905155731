<template>
  <v-card elevation="4">
    <v-card-title>
      Šabloni za nazive fajlova
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="listOfFileNameFormats"
    :server-items-length="options.page * options.itemsPerPage + 1"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    }"
    no-data-text="Nema rezultata"
    loading-text="Učitavanje podataka... Molimo sačekajte"
    class="elevation-1 row-pointer"
    width="100%"
    >
      <template v-slot:[`item.fileNameFormat.createdAt`]="{ item }">
        <span>{{ format_datetime(item.createdAt) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="showCopyButton === true" small class="mr-2" @click="onCopy(item)">mdi-content-copy</v-icon>

        <edit-file-name-format-dialog
          v-if="showCopyButton === false"
          :propFileNameFormat="item"
          @reloadFileNameFormats="getFileNameFormatsFromAPI()"
        />
      </template>

      <template v-slot:[`item.documentType`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ getDocumentTypeIcon(item) }}</v-icon>
          </template>
          <span>{{getDocumentTypeTooltip(item)}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js';
import moment from 'moment';
import DocumentTypes from "../common/documentTypes.js";

import EditFileNameFormatDialog from '../components/EditFileNameFormatDialog.vue';

export default {
  name: "CustomerFileNameFormatsList",
  components: {
    EditFileNameFormatDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      listOfFileNameFormats: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%", sortable: false },
          { text: 'Isporučno mesto', value: 'customer.name', sortable: false },
          { text: 'Tip dokumenta', value: 'documentType', width: "5%", sortable: false },
          { text: 'ID šablona', value: 'fileNameFormat.id', width: "10%", sortable: false },
          { text: 'Šablon', value: 'fileNameFormat.template', width: "30%", sortable: false },
          { text: 'Datum kreiranja', value: 'fileNameFormat.createdAt', width: "15%", sortable: false },
          { text: 'Akcije', value: 'actions', width: "10%", sortable: false }
      ]
    }
  },
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null
    },
    showCopyButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    companyId: {
      handler() {
        this.getFileNameFormatsFromAPI();
      }
    },
    options: {
      handler () {
        this.getFileNameFormatsFromAPI();
      },
      deep: true,
    },
  },
  mounted () {
    this.getFileNameFormatsFromAPI()
  },
  methods: {
    getFileNameFormatsFromAPI() {
      this.loading = true;

      this.options.companyId = this.companyId;
      apiDispatcherOptions.getCustomerFilenameFormats(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfFileNameFormats = responseData;
        })
        .catch(() => {
          this.listOfFileNameFormats = [];
          this.loading = false;
        })
    },

    onCopy(customerFileNameFormat) {
      this.$emit("customerFileNameFormatCopied", customerFileNameFormat);
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },

    getDocumentTypeIcon(customerFilenameFormat) {
      return DocumentTypes.getById(customerFilenameFormat.documentType).letterIcon;
    },

    getDocumentTypeTooltip(customerFilenameFormat) {
      return DocumentTypes.getById(customerFilenameFormat.documentType).name;
    }
  }
}
</script>
