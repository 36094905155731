<template>
  <v-card>
    <v-card-title> Dodavanje alternativnog primaoca </v-card-title>

    <v-card-text class="mt-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="7">
            <v-select
              :items="getDocumentTypes()"
              v-model="newCustomerAltReceiver.documentTypeId"
              item-text="text"
              item-value="value"
              label="Tip dokumenta *"
              required
              outlined
              dense
              hide-details
              v-on:change="documentTypeChanged"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="originalCustomerSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi originalnog primaoca"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto - Originalni primalac</v-list-item-title>
                      <v-list-item-subtitle>{{ this.selectedOriginalCustomer.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="alternativeCustomerSelected"
                  :companyId="companyId"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi alternativnog primaoca"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto - Alternativni primalac</v-list-item-title>
                      <v-list-item-subtitle>{{ this.selectedAlternativeCustomer.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox
              label="Slanje samo alternativnom primaocu"
              v-model="newCustomerAltReceiver.altCustomerOnly"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <small>* označava neophodna polja</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary ma-2 white--text"
        text
        @click="onAddAltReceiver"
        :loading="postingToApi"
      >
        Dodaj
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
  </v-card>
</template>

<script>
import * as customersApi from "../api/customers.js";
import DocumentTypes from "../common/documentTypes.js";

import FindCustomerDialog from "./FindCustomerDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddAltReceiver",
  components: {
    Snackbar,
    FindCustomerDialog
  },
  data: function () {
    return {
      postingToApi: false,
      newCustomerAltReceiver: {
        documentTypeId: null,
        originalReceiverId: null,
        alternativeReceiverId: null,
        altCustomerOnly: false
      },

      selectedOriginalCustomer: {
        name: null
      },
      selectedAlternativeCustomer: {
        name: null
      },

      companyId: null,

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  watch: {
    propOriginalReceiver: {
      handler() {
        this.selectedOriginalCustomer = this.propOriginalReceiver;
        this.companyId = this.selectedOriginalCustomer.companyId;
      }
    },
    propAlternativeReceiver: {
      handler() {
        this.selectedAlternativeCustomer = this.propAlternativeReceiver;
      }
    }
  },
  props: {
    propOriginalReceiver: {
      type: Object,
      default: null,
      required: false
    },
    propAlternativeReceiver: {
      type: Object,
      default: null,
      required: false
    }
  },
  methods: {
    onAddAltReceiver() {
      this.postingToApi = true;

      this.prepareForPost();

      customersApi
        .addNewAlternativeReceiverLink(this.newCustomerAltReceiver, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodata veza između isporučnih mesta. ID veze: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },

    originalCustomerSelected(customer) {
      this.selectedOriginalCustomer = customer;
      this.companyId = customer.companyId;
      this.$emit("companySelected", customer.companyId);
    },

    alternativeCustomerSelected(customer) {
      this.selectedAlternativeCustomer = customer;
    },

    documentTypeChanged() {
      this.$emit("documentTypeSelected", this.newCustomerAltReceiver.documentTypeId);
    },

    prepareForPost() {
      this.newCustomerAltReceiver.originalReceiverId = this.selectedOriginalCustomer.id;
      this.newCustomerAltReceiver.alternativeReceiverId = this.selectedAlternativeCustomer.id;
    }
  },
};
</script>
