<template>
  <v-dialog v-model="dialogFindFileNameFormat" @keydown.esc="dialogFindFileNameFormat = false" persistent max-width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" :small="isSmall" :fab="isCircle" dark v-bind="attrs" v-on="on">
        {{ buttonText }}
        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </template>
    <v-card elevation="4">
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="dialogFindFileNameFormat = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Šabloni za nazive fajlova
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
      :headers="headers"
      :items="listOfFileNameFormats"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ format_datetime(item.createdAt) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js';
import moment from 'moment';

export default {
  name: "FindFileNameFormatDialog",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      dialogFindFileNameFormat: false,

      listOfFileNameFormats: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%", sortable: false },
          { text: 'Šablon', value: 'template', width: "30%", sortable: false },
          { text: 'Datum kreiranja', value: 'createdAt', width: "15%", sortable: false },
          { text: 'Akcije', value: 'actions', width: "10%", sortable: false }
      ]
    }
  },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    toolbarText: {
      type: String,
      required: true
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    options: {
      handler () {
        this.getFileNameFormatsFromAPI();
      },
      deep: true,
    },
  },
  mounted () {
    this.getFileNameFormatsFromAPI()
  },
  methods: {
    getFileNameFormatsFromAPI() {
      this.loading = true;

      this.options.companyId = this.companyId;
      apiDispatcherOptions.getFilenameFormats(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfFileNameFormats = responseData;
        })
        .catch(() => {
          this.listOfFileNameFormats = [];
          this.loading = false;
        })
    },

    onRowClick(fileNameFormat) {
      this.dialogFindFileNameFormat = false;
      this.$emit("chosenFileNameFormat", fileNameFormat);
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
