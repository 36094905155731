<template>
  <v-row>
    <v-col sm="12">
      <v-card v-if="loading === false" width="100%" class="mt-4" elevation="0">
        <v-card-title>
          Email opcije
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text v-if="emailOptionsList.length > 0">
          <v-list dense>
            <v-list-item v-for="item in emailOptionsList" :key="item.id" class="elevation-2 mb-1">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Tip dokumenta: {{ getDocumentType(item.documentType) }}</v-list-item-title>
                <v-list-item-subtitle>Primaoci: {{ joinEmails(item.emailReceivers) }}</v-list-item-subtitle>
                <v-list-item-subtitle>CC: {{ joinEmails(item.ccs) }}</v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-action>
                <email-options-details-dialog
                  :propEmailOptions="item"
                />
              </v-list-item-action>
              <v-list-item-action>
                <edit-email-options-dialog
                  :propEmailOptions="item"
                  @reloadEmailOptions="getEmailOptionsFromAPI()"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else>
          Nema podataka.
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary" :to="getToPath()" >
            Dodaj email opcije
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js'
import DocumentTypes from '../common/documentTypes.js';

import EmailOptionsDetailsDialog from '../components/EmailOptionsDetailsDialog.vue';
import EditEmailOptionsDialog from '../components/EditEmailOptionsDialog.vue';

export default {
  name: "EmailOptionsForCustomer",
  components: {
    EditEmailOptionsDialog,
    EmailOptionsDetailsDialog
  },
  data: function () {
    return {
      loading: false,
      showCopyButton: false,
      emailOptionsList: [],
      farmalogistToUpdate: {},
      options: {
        page: 1,
        itemsPerPage: 10
      }
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getEmailOptionsFromAPI();
      }
    }
  },
  mounted () {
    this.getEmailOptionsFromAPI()
  },
  methods: {
    getEmailOptionsFromAPI() {
      this.loading = true;

      this.options.customerId = this.customerId;
      apiDispatcherOptions.getEmailOptions(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.emailOptionsList = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.emailOptionsList = [];
          this.loading = false;
        })
    },
    getDocumentType(documentTypeId) {
      return DocumentTypes.getById(documentTypeId).name;
    },
    joinEmails(emailArray) {
      if (emailArray === null || emailArray.length === 0) {
        return "";
      }

      return emailArray.join(", ");
    },
    getToPath() {
      return "/sendoptions/email/create/" + this.customerId;
    }
  }
}
</script>
