import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import CompaniesPage from '../views/CompaniesPage.vue'
import CompanyDetailsPage from '../views/CompanyDetailsPage.vue'

import CustomersPage from '../views/CustomersPage.vue'
import CustomerDetailsPage from '../views/CustomerDetailsPage.vue'

import CustomerCustCodesListPage from '../views/CustomerCustCodesListPage.vue';

import DocumentsPage from '../views/DocumentsPage.vue'
import DocumentSearchByIdPage from '../views/DocumentSearchByIdPage.vue'
import DocumentDetailsPage from '../views/DocumentDetailsPage.vue'

import FilesPage from '../views/FilesPage.vue'
import FileDetailsPage from '../views/FileDetailsPage.vue'

import CodebooksPage from '../views/CodebooksPage.vue'
import CodebookAddPage from '../views/CodebookAddPage.vue'
import CodebooksPairingPage from '../views/CodebooksPairingPage.vue'
import CodebookUpdatePage from '../views/CodebookUpdatePage.vue'

import UsersPage from '../views/UsersPage.vue'
import UserAddPage from '../views/UserAddPage.vue'
import PortalUserAddPage from '../views/PortalUserAddPage.vue'

import DispatcherPage from '../views/DispatcherPage.vue'
import AddDispatcherRulePage from '../views/AddDispatcherRulePage.vue';
import AddDispatcherExceptionPage from '../views/AddDispatcherExceptionPage.vue';
import DispatcherRuleDetailsPage from '../views/DispatcherRuleDetailsPage.vue';

import SendOptionsPage from '../views/SendOptionsPage.vue';
import AddEmailOptionsPage from '../views/AddEmailOptionsPage.vue';
import AddApiOptionsPage from '../views/AddApiOptionsPage.vue';
import AddCustomerFileNameFormatPage from '../views/AddCustomerFileNameFormatPage.vue';
import AddFtpClientOptionsPage from '../views/AddFtpClientOptionsPage.vue';

import AddCustomerAltReceiverPage from '../views/AddCustomerAltReceiverPage.vue';
import AddAltReceiversForCompanyPage from '../views/AddAltReceiversForCompanyPage.vue';

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/companies',
    name: 'Companies',
    component: CompaniesPage
  },
  {
    path: '/companies/:id',
    name: 'CompanyDetailsPage',
    component: CompanyDetailsPage
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersPage
  },
  {
    path: '/customers/:id',
    name: 'CustomerDetailsPage',
    component: CustomerDetailsPage
  },
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsPage
  },
  {
    path: '/codebooks',
    name: 'Codebooks',
    component: CodebooksPage
  },
  {
    path: '/codebooks/create',
    name: 'CodebookAdd',
    component: CodebookAddPage
  },
  {
    path: '/codebooks/pairing',
    name: 'CodebooksPairing',
    component: CodebooksPairingPage
  },
  {
    path: '/codebooks/update/:id',
    name: 'CodebookUpdate',
    component: CodebookUpdatePage
  },
  {
    path: '/document/searchById',
    name: 'DocumentSearchById',
    component: DocumentSearchByIdPage
  },
  {
    path: '/documents/:id',
    name: 'DocumentDetailsPage',
    component: DocumentDetailsPage
  },
  {
    path: '/users',
    name: 'UsersPage',
    component: UsersPage
  },
  {
    path: '/users/createUser',
    name: 'UserAddPage',
    component: UserAddPage
  },
  {
    path: '/users/createPortalUser',
    name: 'PortalUserAddPage',
    component: PortalUserAddPage
  },
  {
    path: '/files',
    name: 'FilesPage',
    component: FilesPage
  },
  {
    path: '/files/:id',
    name: 'FileDetailsPage',
    component: FileDetailsPage
  },
  {
    path: '/customercustcodes',
    name: 'CustomerCustCodesListPage',
    component: CustomerCustCodesListPage
  },
  {
    path: '/dispatcher',
    name: 'DispatcherPage',
    component: DispatcherPage
  },
  {
    path: '/dispatcher/create-rule',
    name: 'AddDispatcherRulePage',
    component: AddDispatcherRulePage
  },
  {
    path: '/dispatcher/create-exception',
    name: 'AddDispatcherExceptionPage',
    component: AddDispatcherExceptionPage
  },
  {
    path: '/dispatcher/details/:id',
    name: 'DispatcherRuleDetailsPage',
    component: DispatcherRuleDetailsPage
  },
  {
    path: '/sendoptions',
    name: 'SendOptionsPage',
    component: SendOptionsPage
  },
  {
    path: '/sendoptions/email/create/:id?',
    name: 'AddEmailOptionsPage',
    component: AddEmailOptionsPage
  },
  {
    path: '/sendoptions/api/create/:id?',
    name: 'AddApiOptionsPage',
    component: AddApiOptionsPage
  },
  {
    path: '/sendoptions/customerFileNameFormat/create/:id?',
    name: 'AddCustomerFileNameFormatPage',
    component: AddCustomerFileNameFormatPage
  },
  {
    path: '/sendoptions/ftp/create',
    name: 'AddFtpClientOptionsPage',
    component: AddFtpClientOptionsPage
  },
  {
    path: '/altReceivers/create/:id?',
    name: 'AddCustomerAltReceiverPage',
    component: AddCustomerAltReceiverPage
  },
  {
    path: '/altReceivers/create/byCompany/:id?',
    name: 'AddAltReceiversForCompanyPage',
    component: AddAltReceiversForCompanyPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isauth = store.getters.isLoggedIn
  if (to.name !== 'Login' && isauth === false) {
    if (from.name === 'Login') {
      next(false)
    }
    next({ path: '/login' })
  }
  else {
    next()
  }
})

export default router
