<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-card>
            <v-card-title> Dodavanje pravila </v-card-title>

            <v-card-text class="mt-4">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="7">
                    <v-select
                      :items="software"
                      v-model="newRule.softwareID"
                      item-text="name"
                      item-value="id"
                      label="Software *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="getDocumentTypes()"
                      v-model="newRule.documentTypeID"
                      item-text="text"
                      item-value="value"
                      label="Tip dokumenta *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="outputFormats"
                      v-model="newRule.outputFormatID"
                      item-text="name"
                      item-value="id"
                      label="Izlazni format *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="channelTypes"
                      v-model="newRule.channelID"
                      item-text="description"
                      item-value="id"
                      label="Kanal prenosa *"
                      required
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select
                      :items="pairTypes"
                      v-model="newRule.pair"
                      item-text="text"
                      item-value="value"
                      label="Tip uparivanja *"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="newRule.sendOnceADay"
                      label="Slanje jednom dnevno"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="newRule.resetUserProcessed"
                      label="Resetovanje 'UserProcessed' polja"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              <small>* označava neophodna polja</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="primary ma-2 white--text"
                text
                @click="onAddDispatcherRule"
                :loading="postingToApi"
              >
                Dodaj pravilo
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as dispatcherApi from "../api/dispatcher";
import * as utilsApi from "../api/utils";
import DocumentTypes from "../common/documentTypes.js";

import Snackbar from "../components/Snackbar.vue";

export default {
  name: "AddDispatcherRulePage",
  components: {
    Snackbar
  },
  data: function () {
    return {
      postingToApi: false,
      newRule: {},

      pairTypes: [
        { value: 1, text: "1 - Original" },
        { value: 2, text: "2 - Primalac" },
        { value: 4, text: "4 - AUB" },
      ],

      channelTypes: [],
      outputFormats: [],
      software: [],

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  mounted() {
    this.getSoftware();
    this.getChannelTypes();
    this.getOutputFormats();
  },
  methods: {
    onAddDispatcherRule() {
      this.postingToApi = true;

      dispatcherApi
        .addRule(this.newRule, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodat izuzetak. ID: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    getSoftware() {
      utilsApi
        .getSoftware(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.software = responseData;
        })
        .catch(() => {});
    },
    getChannelTypes() {
      utilsApi
        .getChannelTypes(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.channelTypes = responseData;
        })
        .catch(() => {});
    },
    getOutputFormats() {
      utilsApi
        .getOutputFormats(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.outputFormats = responseData;
        })
        .catch(() => {});
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    }
  },
};
</script>
