<template>
  <v-row>
    <v-col sm="12">
      <v-card v-if="loading === false" width="100%" class="mt-4" elevation="0">
        <v-card-title>
          Šabloni za nazive fajlova
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text v-if="customerFileNameFormatsList.length > 0">
          <v-list dense>
            <v-list-item v-for="item in customerFileNameFormatsList" :key="item.id" class="elevation-2 mb-1">
              <v-list-item-icon>
                <v-icon>mdi-form-textbox</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Šablon: {{ item.fileNameFormat.template }}</v-list-item-title>
                <v-list-item-subtitle>ID šablona: {{ item.fileNameFormat.id }}</v-list-item-subtitle>
                <v-list-item-subtitle>Tip dokumenta: {{ getDocumentTypeName(item.documentType) }}</v-list-item-subtitle>
                <v-list-item-subtitle>Datum kreiranja: {{ format_datetime(item.createdAt) }}</v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-action>
                <edit-file-name-format-dialog
                  v-if="showCopyButton === false"
                  :propFileNameFormat="item"
                  @reloadFileNameFormats="getFileNameFormatsFromAPI()"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else>
          Nema podataka.
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary" :to="getPathToCreateCustomerFileNameFormat()">
            Dodaj šablon
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as apiDispatcherOptions from '../api/dispatcherOptions.js'
import moment from "moment";
import DocumentTypes from "../common/documentTypes.js";

import EditFileNameFormatDialog from '../components/EditFileNameFormatDialog.vue';

export default {
  name: "FileNameFormatsForCustomer",
  components: {
    EditFileNameFormatDialog
  },
  data: function () {
    return {
      loading: false,
      showCopyButton: false,
      customerFileNameFormatsList: [],
      farmalogistToUpdate: {},
      options: {
        page: 1,
        itemsPerPage: 10
      }
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getFileNameFormatsFromAPI();
      }
    }
  },
  mounted () {
    this.getFileNameFormatsFromAPI()
  },
  methods: {
    getFileNameFormatsFromAPI() {
      this.loading = true;

      this.options.customerId = this.customerId;
      apiDispatcherOptions.getCustomerFilenameFormats(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customerFileNameFormatsList = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.customerFileNameFormatsList = [];
          this.loading = false;
        })
    },
    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },
    getPathToCreateCustomerFileNameFormat() {
      return "/sendoptions/customerFileNameFormat/create/" + this.customerId;
    },
    getDocumentTypeName(docType) {
      return DocumentTypes.getById(docType).name;
    }
  }
}
</script>
