<template>
  <v-card>
    <v-card-title> Povezivanje isporučnog mesta i šablona </v-card-title>

    <v-card-text class="mt-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-customer-dialog
                  @update="customerSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Isporučno mesto</v-list-item-title>
                      <v-list-item-subtitle>{{this.selectedCustomer.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col sm="2" class="mt-3">
                <find-file-name-format-dialog
                  @chosenFileNameFormat="fileNameFormatSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi šablon"
                />
              </v-col>
              <v-col sm="10">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Šablon</v-list-item-title>
                      <v-list-item-subtitle>{{this.selectedFileNameFormat.template}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-select
              :items="getDocumentTypes()"
              v-model="documentType"
              item-text="text"
              item-value="value"
              label="Tip dokumenta *"
              required
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary ma-2 white--text"
        text
        @click="onAddCustomerFileNameFormat"
        :loading="postingToApi"
      >
        Dodaj vezu
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show" />
  </v-card>
</template>

<script>
import * as dispatcherOptionsApi from "../api/dispatcherOptions.js";
import DocumentTypes from "../common/documentTypes.js";

import FindFileNameFormatDialog from "./FindFileNameFormatDialog.vue";
import FindCustomerDialog from "./FindCustomerDialog.vue";
import Snackbar from "./Snackbar.vue";

export default {
  name: "AddCustomerFileNameFormat",
  components: {
    Snackbar,
    FindCustomerDialog,
    FindFileNameFormatDialog
  },
  data: function () {
    return {
      postingToApi: false,
      selectedCustomer: {
        name: null,
        id: null
      },
      selectedFileNameFormat: {
        template: ""
      },
      documentType: null,

      snackbar: {
        show : false,
        text : "",
        isError : false
      }
    };
  },
  watch: {
    copiedCustomerFileNameFormat: {
      handler() {
        this.selectedCustomer = this.copiedCustomerFileNameFormat.customer;
        this.selectedFileNameFormat = this.copiedCustomerFileNameFormat.fileNameFormat;
        this.documentType = this.copiedCustomerFileNameFormat.documentType;
      }
    },
    propCustomer: {
      handler() {
        if (this.propCustomer !== null) {
          this.selectedCustomer = this.propCustomer;
        }
      }
    }
  },
  props: {
    propCustomer: {
      type: Object,
      default: null,
      required: false
    },
    copiedCustomerFileNameFormat: {
      type: Object,
      default: null,
      required: false
    }
  },
  methods: {
    onAddCustomerFileNameFormat() {
      this.postingToApi = true;

      let toPost = this.prepareForPost();

      dispatcherOptionsApi
        .addCustomerFileNameFormat(toPost, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.showNotification("Uspešno dodata veza. ID: " + responseData.id);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },

    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    customerSelected(customer) {
      this.selectedCustomer = customer;
      this.$emit("companySelected", customer.companyId);
    },

    fileNameFormatSelected(fileNameFormat) {
      this.selectedFileNameFormat = fileNameFormat;
    },
    prepareForPost() {
      let toPost = {
        customerId: this.selectedCustomer.id,
        nameFormatId: this.selectedFileNameFormat.id
      }

      return toPost;
    },

    getDocumentTypes() {
      return DocumentTypes.getDocumentTypesForSelectElement();
    },
  },
};
</script>
