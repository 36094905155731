<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col sm="6">
        <add-alt-receiver
          :propOriginalReceiver="customer"
          :propAlternativeReceiver="selectedAlternativeReceiver"
          @companySelected="onCompanySelected"
          @documentTypeSelected="onDocumentTypeSelected"
        />
      </v-col>

      <v-col sm="8">
        <alt-receiver-suggestions
          :companyId="selectedCompanyId"
          :documentType="selectedDocumentType"
          @altReceiverSelected="passSelectedAltReceiver"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCustomers from "../api/customers.js";

import AddAltReceiver from '../components/AddAltReceiver.vue';
import AltReceiverSuggestions from '../components/AltReceiverSuggestions.vue';

export default {
  name: "AddCustomerAltReceiverPage",
  components: {
    AddAltReceiver,
    AltReceiverSuggestions
  },
  data() {
    return {
      selectedCompanyId: null,
      selectedDocumentType: null,

      selectedAlternativeReceiver: null,
      customer: null
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getCustomer(id);
    }
  },
  methods: {
    onCompanySelected(compId) {
      this.selectedCompanyId = compId;
    },

    onDocumentTypeSelected(documentType) {
      this.selectedDocumentType = documentType;
    },

    passSelectedAltReceiver(altReceiver) {
      this.selectedAlternativeReceiver = altReceiver;
    },

    getCustomer(id) {
      this.loading = true;

      apiCustomers.getCustomer(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customer = responseData;
          this.selectedCompanyId = this.customer.companyId;
          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.customer = null;
          this.loading = false;
        })
    },
  }
}
</script>
